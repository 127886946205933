import * as actionTypes from "../actionTypes"



export const websocketConnected=()=>({
    type:actionTypes.WEBSOCKET_CLIENT_CONNECTED
})

export const websocketDisconnected=()=>({
    type:actionTypes.WEBSOCKET_CLIENT_DISCONNECTED
})


// TO show popup to host to confrim whether he want to start new insatance of the quiz or not

export const showNewInstancePopup=()=>({
    type:actionTypes.SHOW_NEW_INSTANCE_POPUP
})

// To hide the popup
export const hideNewInstancePopup=()=>({
    type:actionTypes.HIDE_NEW_INSTANCE_POPUP
})


// To Start the New Instance
export const startNewInstance=()=>({
    type:actionTypes.START_NEW_INSTANCE,
})

// To Continue the previous instance
export const startPreviousInstance=()=>({
    type:actionTypes.START_PREVIOUS_INSTANCE,
})


// To Start the new Instance loader

export const startNewInstanceLoader=()=>({
    type:actionTypes.START_NEW_INSTANCE_START
})

// To Stop the new Instance loader

export const stopNewInstanceLoader=()=>({
    type:actionTypes.START_NEW_INSTANCE_FINISH
})

// To update Quiz Id in Redux
export const updateQuizId=(id)=>({
    type:actionTypes.UPDATE_START_NEW_INSTANCE_SUCCESS,
    payload:{id}
})

// To check whether quiz is ongoing or not

export const checkQuizOngoing=()=>({
    type:actionTypes.CHECK_QUIZ_ONGOING
})


export const checkQuizStatus=()=>({
    type:actionTypes.CHECK_QUIZ_STATUS
})


// To update Quiz Ongoing in Redux
export const updateQuizOngoing=(data)=>({
    type:actionTypes.UPDATE_CHECK_QUIZ_ONGOING_SUCCESS,
    payload:{data}
})


// To update the quiz status in redux
export const updateQuizStatus=(data)=>({
    type:actionTypes.UPDATE_CHECK_QUIZ_STATUS_SUCCESS,
    payload:{data}
})


// To fetch the participants
export const getParticpants=()=>({
    type:actionTypes.GET_PARTICIPANT_LIST
})

// To update participant list in redux
export const updateParticipantList=(data)=>({
    type:actionTypes.UPDATE_GET_PARTICIPANT_LIST_SUCCESS,
    payload:{data}
})


// To start next question
export const showNextQuestion = () => ({
    type: actionTypes.SHOW_NEXT_QUESTION,
})

// To start next question loader
export const startNextQuestionLoader=()=>({
    type:actionTypes.SHOW_NEXT_QUESTION_START
})
// To stop next question loader

export const stopNextQuestionLoader=()=>({
    type:actionTypes.SHOW_NEXT_QUESTION_FINISH
})


// To check whether it is last question or not

export const isLastQuestion=(data)=>({
    type:actionTypes.IS_LAST_QUESTION,
    payload:{data}
})

// Updating the current question in redux
export const updateCurrentQuestion=(data)=>({
    type:actionTypes.UPDATE_GET_CURRENT_QUESTION_SUCCESS,
    payload:{data}
})

// Show Leaderboard
export const showLeaderBoard = () => ({
    type: actionTypes.SHOW_LEADERBOARD,
})


// To logout the participant and host
export const logout=(data)=>({
    type:actionTypes.LOGOUT,
    payload:{data}
})

// To end the quiz
export const endQuiz=(data)=>({
    type:actionTypes.END_QUIZ,
    payload:{data}
})

// submit answer
export const submitAnswer = (data) => ({
    type: actionTypes.SUBMIT_ANSWER,
    payload: { data }
})

//update submit answer
export const updateSubmitAnswerSuccess = (data) => ({
    type: actionTypes.UPDATE_SUBMIT_ANSWER_SUCCESS,
    payload: { data }
})
export const updateSubmitAnswerFail = (data) => ({
    type: actionTypes.UPDATE_SUBMIT_ANSWER_FAIL,
    payload: { data }
})


//update show answer screen
export const updateShowAnswerScreen=(data)=>({
    type:actionTypes.UPDATE_SHOW_ANSWER_SCREEN_SUCCESS,
    payload:{data}
})



//to cancel Quiz
export const cancelQuiz=()=>({
    type:actionTypes.CANCEL_QUIZ,
})

// To check whether the participant is winner

export const isWinner=()=>({
    type:actionTypes.IS_WINNER
})


//update remaining time
export const isTimerUp=()=>({
    type:actionTypes.IS_TIMER_UP,
})

//update all question at once
export const updateAllQuestionSuccess=(data)=>({
    type:actionTypes.UPDATE_FETCH_ALL_QUESTION_SUCCESS,
    payload:{data}
})
