import * as actionTypes from "../actionTypes"



export const handleParticipantLogin = (data) => ({
    type: actionTypes.PARTICIPANT_AUTH,
    payload: { data }
})


export const handleSuccessLogin = (data) => ({
    type: actionTypes.UPDATE_PARTICIPANT_AUTH_SUCCESS,
    payload: { data }
})



export const handleFailLogin = (data) => ({
    type: actionTypes.UPDATE_PARTICIPANT_AUTH_FAIL,
    payload: { data }
})

export const loaderStart = () => ({
    type: actionTypes.PARTICIPANT_AUTH_START
})


export const loaderFinish = () => ({
    type: actionTypes.PARTICIPANT_AUTH_FINISH
})

export const setPartCurrentUser=(data)=>({
    type:actionTypes.SET_PART_CURRENT_USER,
    payload:{data}
})

// export const checkTokenVailid = () => ({
//     type: actionTypes.CHECK_TOKEN_VAILID,
// })

// export const logoutSuccess = () => ({
//     type: actionTypes.UPDATE_AUTH_TOKEN_REMOVE
// })