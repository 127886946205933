import * as actionTypes from "../actionTypes"
import { updateObject } from "./utility"

const initialState = {
    loading: false,
    token: null,
    data: null,
    error: null,
    pin: null,
    isAuthenticated: false
}



const updateFail = (action, state) => {
    return updateObject(state, {
        error: action.payload.data
    })
}

const updateSuccess = (action, state) => {
    return updateObject(state, {
        data: action.payload.data,
        token: action.payload.data.access,
        isAuthenticated: true,
        error: null
    })
}

const LoaderStart = (action, state) => {
    return updateObject(state, {
        loading: true
    })
}
const LoaderOff = (action, state) => {
    return updateObject(state, {
        loading: false
    })
}

const savePin = (action, state) => {
    return updateObject(state, {
        pin: action.payload
    })
}

const removeAuth = (acton, state) => {
    return updateObject(state, {
    loading: false,
    token: null,
    data: null,
    error: null,
    pin: null,
    isAuthenticated: false
    })
}






const ParticipantAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PARTICIPANT_AUTH_SUCCESS: return updateSuccess(action, state);
        case actionTypes.UPDATE_PARTICIPANT_AUTH_FAIL: return updateFail(action, state);
        case actionTypes.PARTICIPANT_AUTH_START: return LoaderStart(action, state)
        case actionTypes.PARTICIPANT_AUTH_FINISH: return LoaderOff(action, state)
        case actionTypes.REMOVE_TOKEN: return removeAuth(action, state)
        case actionTypes.SAVE_PARTICIPATE_PIN: return savePin(action, state)
        default:
            return state
    }
}



export default ParticipantAuthReducer