import { api } from "./_api"
import { hostAuthMdl } from "./_HostAuth"
import { participantAuthMdl } from "./_ParticipantAuth"
import { quizMdl } from "./_Quiz"
import {quizV2mdl} from "./_QuizV2"
// import {api} from "./axios"
export const Middleware = [
    api,
    ...hostAuthMdl,
    ...participantAuthMdl,
    ...quizV2mdl,
    // ...quizMdl
]