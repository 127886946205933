import React from 'react'
import {Modal,Button} from "rsuite"
import PropTypes from "prop-types"
export default function Popup({isShow,confirm,cancel,confirmBtnText,cancelBtnText,content,loading}) {
    return (
        <div className="modal-container">
        <Modal backdrop="static" show={isShow}  size="xs"  >
          <Modal.Body>
            {content}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={confirm} appearance="primary" loading={loading} >
              {confirmBtnText?confirmBtnText:"Ok"}
            </Button>
            <Button onClick={cancel} appearance="subtle">
              {cancelBtnText?cancelBtnText:"Cancel"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
}



Popup.propTypes={
    isShow:PropTypes.bool.isRequired,
    content:PropTypes.string.isRequired,
    confirmBtnText:PropTypes.string,
    cancelBtnText:PropTypes.string,
    confirm:PropTypes.func.isRequired,
    cancel:PropTypes.func.isRequired,
    loading:PropTypes.bool
}