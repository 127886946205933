
// export const BASE_URI = "http://quizapp.qilinlab.com:8020"
export const BASE_URI = "/api"


export const GET_CURRENT_QUESTION = "/quiz/questions/";
export const JOIN_QUIZ = "/participate/join/";
export const PARTICIPANT_LIST = "/participate/quiz_participants/";
export const CHECK_QUIZ_START = "/participate/check_quiz_start/";
export const START_QUIZ = "/quiz/start_by_host/";
export const HOST_LOGIN = "/host/login/";
export const START_QUESTION = "/start_question_by_host/"


// New API

export const REPLACE_INSTANCE="/quiz/host/replace_instance/";
export const CHECK_QUIZ_ONGOING= "/participate/is_ongoing/";
export const CHECK_QUIZ_STATUS="/participate/quiz_status/";
export const LEADERBOARD="/participate/leaderboard_screen/";
export const NEXT_QUESTION = "/quiz/host/next_question/"
export const SHOW_LEADERBOARD = "/quiz/host/show_leaderboard/";
export const SUBMIT_ANSWER = "/participate/submit_answer/";
export const ANSWER="/participate/answer_screen/"
export const QUESTION="/quiz/question_screen/"
export const END_QUIZ=  "/quiz/host/end_quiz/"
export const CANCEL_QUIZ="/quiz/host/cancel_quiz/"
export const TIME_REMAINING="/quiz/time_remaining/"
export const ALL_QUESTION= "/quiz/all_question/";