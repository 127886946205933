import * as actionTypes from "../actionTypes"
import {updateObject} from "./utility"

const initialState={
   newInstanceLoader:false,
   isWebSocketConnected:false,
   showNewInstancePopup:false,
   isQuizOngoing:null,
   quizStatus:null,
   startQuestionLoader:false,
   participants:[],
   question:null,
   allQuestion:null,
   leaderboardLoading:false,
   correctAnswer:[],
   answer:null,
   cancelQuizLoader:false,
   isLastQuestion:false,
   isWinner:false,
   questionLoader:false,
   submittedAnswer:null,
   isTimerUp:false,
   remainingTime:null,
   submitAnswerError:null
}


//Show new intance popup
const showNewInstancePopup=(action,state)=>{
    return updateObject(state,{
        showNewInstancePopup:true,
    })
}

//Hide new intance popup
const hideNewInstancePopup=(action,state)=>{
    return updateObject(state,{
        showNewInstancePopup:false,
    })
}


// Start loader while creating new instance
const startNewInstanceLoader=(state)=>{
    return updateObject(state,{
        newInstanceLoader:true
    })
}

// Stop loader after creating or unable to create new intance
const stopNewInstanceLoader=(state)=>{
    return updateObject(state,{
        newInstanceLoader:false
    })
}


// Update the state of Quiz Ongoing
const quizOngoing=(action,state)=>{
    return updateObject(state,{
        isQuizOngoing:action.payload.data
    })
}


// Updating the status of Quiz
const updateQuizStatus=(action,state)=>{
    return updateObject(state,{
        quizStatus:action.payload.data
    })
}


//Updating the particpants
const updateParticipants=(action,state)=>{
    return updateObject(state,{
        participants:action.payload.data,
        submittedAnswer:null,
        submitAnswerError:null,
        remainingTime:null
    })
}


//Start Loader while showing next question
const startQuestionLoader=(action,state)=>{
    return updateObject(state,{
        startQuestionLoader:true
    })
}

//Stop loader after showing next question
const stopQuestionLoader=(action,state)=>{
    return updateObject(state,{
        startQuestionLoader:false
    })
}

//Saving the current question
const updateCurrentQuestion=(action,state)=>{
    return updateObject(state,{
        question:action.payload.data,
        // submittedAnswer:null
    })
}


//Start loader while calling leaderboard api
const startLeaderBoardLoading=(action,state)=>{
    return updateObject(state,{
        leaderboardLoading:true
    })
}

//stop loader after success or failure of Leaderboard api

const stopLeaderBoardLoading=(action,state)=>{
    return updateObject(state,{
        leaderboardLoading:false
    })
}

// saving correct answer
const saveCurrentAnswer=(action,state)=>{
    return updateObject(state,{
        correctAnswer:action.payload.correct
    })
}


// reseting the question
const resetQuestion=(action,state)=>{
    return updateObject(state,{
        question:null
    })
}

// show answer screen
const showAnswerScreen=(action,state)=>{
    return updateObject(state,{
        answer:action.payload.data
    })
}


// cancel quiz loader
const startCancelQuizLoader=(action,state)=>{
    return updateObject(state,{
        cancelQuizLoader:true
    })
}

const stopCancelQuizLoader=(action,state)=>{
    return updateObject(state,{
        cancelQuizLoader:false
    })
}

const isLastQuestion=(action,state)=>{
    return updateObject(state,{
        isLastQuestion:action.payload.data
    })
}


const isWinner=(action,state)=>{
    return updateObject(state,{
        isWinner:true
    })
}


//get current Quesiton loader start
const startCurrentQuestionLoader=(action,state)=>{
    return updateObject(state,{
        questionLoader:true
    })
}

//get current Quesiton loader stop
const stopCurrentQuestionLoader=(action,state)=>{
    return updateObject(state,{
        questionLoader:false
    })
}

// to update submitted answer response
const updateSubmitAnswerSuccess=(action,state)=>{
    return updateObject(state,{
        submittedAnswer:action.payload.data
    })
}


const isTimerUp=(action,state)=>{
    return updateObject(state,{
        isTimerUp:true
    })
}

const updateRemainingTime=(action,state)=>{
    return updateObject(state,{
        remainingTime:action.payload.data
    })
}




//fetching all question at once
const updateAllQuestion=(action,state)=>{
    return updateObject(state,{
        allQuestion:action.payload.data
    })
}

const resetAll=(action,state)=>{
    return updateObject(state,{
        allQuestion:null,
        submittedAnswer:null,
        correctAnswer:[]
    })
}


const websocketConnected=(action,state)=>{
    return updateObject(state,{
        isWebSocketConnected:true
    })
}

const websocketDisconnected=(action,state)=>{
    return updateObject(state,{
        isWebSocketConnected:false
    })
}

const updateSubmitAnswerFail=(action,state)=>{
    return updateObject(state,{
        submitAnswerError: action.payload.data?.msg || action.payload.data
    })
}

const QuizV2Reducer=(state=initialState,action)=>{
    switch (action.type){
        case actionTypes.START_NEW_INSTANCE_START:return startNewInstanceLoader(state)
        case actionTypes.START_NEW_INSTANCE_FINISH:return stopNewInstanceLoader(state)
        case actionTypes.UPDATE_CHECK_QUIZ_ONGOING_SUCCESS:return quizOngoing(action,state)
        case actionTypes.UPDATE_CHECK_QUIZ_STATUS_SUCCESS:return updateQuizStatus(action,state)
        case actionTypes.UPDATE_GET_PARTICIPANT_LIST_SUCCESS:return updateParticipants(action,state)
        case actionTypes.SHOW_NEXT_QUESTION_START:return startQuestionLoader(action,state)
        case actionTypes.SHOW_NEXT_QUESTION_FINISH:return stopQuestionLoader(action,state)
        case actionTypes.UPDATE_GET_CURRENT_QUESTION_SUCCESS:return updateCurrentQuestion(action,state)
        case actionTypes.SHOW_LEADERBOARD_START:return startLeaderBoardLoading(action,state)
        case actionTypes.SHOW_LEADERBOARD_FINISH:return stopLeaderBoardLoading(action,state)
        case actionTypes.SHOW_LEADERBOARD_SUCCESS:return resetQuestion(action,state)
        case actionTypes.SAVE_CORRECT_ANSWER:return saveCurrentAnswer(action,state)
        case actionTypes.UPDATE_SHOW_ANSWER_SCREEN_SUCCESS:return showAnswerScreen(action,state)
        case actionTypes.CANCEL_QUIZ_START:return startCancelQuizLoader(action,state)
        case actionTypes.CANCEL_QUIZ_FINISH:return stopCancelQuizLoader(action,state)
        case actionTypes.SHOW_NEW_INSTANCE_POPUP:return showNewInstancePopup(action,state)
        case actionTypes.HIDE_NEW_INSTANCE_POPUP:return hideNewInstancePopup(action,state)
        case actionTypes.IS_LAST_QUESTION:return isLastQuestion(action,state)
        case actionTypes.IS_WINNER:return isWinner(action,state)
        case actionTypes.GET_CURRENT_QUESTION_START:return startCurrentQuestionLoader(action,state)
        case actionTypes.GET_CURRENT_QUESTION_FINISH:return stopCurrentQuestionLoader(action,state)
        case actionTypes.UPDATE_SUBMIT_ANSWER_SUCCESS:return updateSubmitAnswerSuccess(action,state)
        case actionTypes.UPDATE_SUBMIT_ANSWER_FAIL:return updateSubmitAnswerFail(action,state)
        case actionTypes.IS_TIMER_UP:return isTimerUp(action,state)
        case actionTypes.UPDATE_GET_REMAINING_TIME_SUCCESS:return updateRemainingTime(action,state)
        case actionTypes.UPDATE_FETCH_ALL_QUESTION_SUCCESS:return updateAllQuestion(action,state)
        case actionTypes.REMOVE_TOKEN:return resetAll(action,state)
        case actionTypes.UPDATE_PARTICIPANT_AUTH_SUCCESS:return resetAll(action,state)
        case actionTypes.UPDATE_HOST_AUTH_SUCCESS:return resetAll(action,state)
        case actionTypes.START_NEW_INSTANCE_SUCCESS:return resetAll(action,state)
        case actionTypes.WEBSOCKET_CLIENT_CONNECTED:return websocketConnected(action,state)
        case actionTypes.WEBSOCKET_CLIENT_DISCONNECTED:return websocketDisconnected(action,state)
        default:
            return state
    }
}



export default QuizV2Reducer