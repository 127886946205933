import * as actionTypes from "../actionTypes"
import {apiRequest} from "../actions/api"
import * as API from "../../api/api"
import * as trigger from "../actions/quizV2"
import history from "../../helpers/history"
import {Alert} from 'rsuite'
import { store } from "../store/index"

// Global Functions

//To get Quiz Id
const getQuizId=()=>{
    const state = store.getState()
    const quizId = state.HostAuthReducer.profile?.quiz_id? state.HostAuthReducer.profile.quiz_id:state.ParticipantAuthReducer.data?.quiz_id
    return{
        quiz_id:quizId
    }

}

// To get Question ID
const getQuestionId=()=>{
    const state = store.getState()
    const id=state.QuizV2Reducer.quizStatus?.question_id
    console.log("Question IDD:",id)
    return {
        question_id:id
    }
}

// To get Participant ID
const getParticipantId=()=>{
    const state=store.getState()
    const id=state.ParticipantAuthReducer.data?.participant_id
    return{
        participant_id:id
    }
}

// To check authentication
const isAuthenticated=(side)=>{
    const state = store.getState()
    const isPartAuth = JSON.parse(localStorage.getItem('ParticipantAuthenticated')) ||false
    const isHostAuth=state.HostAuthReducer.isAuthenticated
    if(side=="part"){
        return isPartAuth
    }
    else if (side=="host"){
        return isHostAuth
    }else{
        if(isPartAuth||isHostAuth){
            return true
           }else{
               return false
           }
    }
    
}


// To Start the new instance
export const startNewInstance=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.START_NEW_INSTANCE){
        let data=getQuizId()
        let onSuccess=actionTypes.START_NEW_INSTANCE_SUCCESS;
        let onFail=actionTypes.START_NEW_INSTANCE_FAIL;
        dispatch(apiRequest('POST',API.REPLACE_INSTANCE,null,onSuccess,onFail,data,null))
        dispatch(trigger.startNewInstanceLoader())
    }
}

// Update new instance in Redux
export const startNewInstanceSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.START_NEW_INSTANCE_SUCCESS){
        let data=action.payload;//----------Response from api
        if(data){
            dispatch(trigger.updateQuizId(data.quiz_id))
        }
        dispatch(trigger.stopNewInstanceLoader())
        dispatch(trigger.hideNewInstancePopup())
    }
}

// Handling Start New Instance Exception

export const startNewInstanceFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type=== actionTypes.START_NEW_INSTANCE_FAIL){
        let data=action.payload;//----------Response from api
        if(data){
            console.log("Error: ",data)
        }
        dispatch(trigger.stopNewInstanceLoader())
    }
}

// To check whether quiz is ongoing or not                                                  -------CONTINIOUS CALL

export const checkQuizOngoing=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.CHECK_QUIZ_ONGOING){
        let data=getQuizId()
        let onSuccess=actionTypes.CHECK_QUIZ_ONGOING_SUCCESS;
        let onFail=actionTypes.CHECK_QUIZ_ONGOING_FAIL;
        console.log("Data: ",data)
            dispatch(apiRequest('GET',API.CHECK_QUIZ_ONGOING,data,onSuccess,onFail,null,null))
    }
}

// Updating Quiz ongoing status in redux

export const checkQuizOngoingSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.CHECK_QUIZ_ONGOING_SUCCESS){
        let data=action.payload;//----------Response from api
        if(data){
            if(data.ongoing){
                dispatch(trigger.updateQuizOngoing(data))
                //Continious Call
                setTimeout(()=>{
                    dispatch({type:actionTypes.CHECK_QUIZ_ONGOING})               
                },10000)
            //    setTimeout(()=>{
            //         dispatch({type:actionTypes.CHECK_QUIZ_STATUS})
            //     },2000)
           
            }else{ 
                dispatch({type:actionTypes.LOGOUT}) 
                // Logout the user and clear all storage
            }
        }
    }
}

// Handling the Quiz Ongoing Exception

export const checkQuizOngoingFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.CHECK_QUIZ_ONGOING_FAIL){
        let data=action.payload;//----------Response from api
        if(data){
            //Handle the error here
            setTimeout(()=>{
                dispatch({type:actionTypes.CHECK_QUIZ_ONGOING})
            },2000)
        }
    }
}


// Checking Quiz Status is if "ONGOING===TRUE"         --CONTINIOUS CALL

// export const checkQuizStatus=({dispatch})=>next=>action=>{
//     next(action)
//     if(action.type===actionTypes.CHECK_QUIZ_STATUS){
//         let data=getQuizId()
//         let onSuccess=actionTypes.CHECK_QUIZ_STATUS_SUCCESS;
//         let onFail=actionTypes.CHECK_QUIZ_STATUS_FAIL;
//         dispatch(apiRequest('GET',API.CHECK_QUIZ_STATUS,data,onSuccess,onFail,null,null))
//     }
// }


// Heart of the Application
//-- Handling the Quiz Route here--------------------------------------------------------
export const checkQuizStatusSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.CHECK_QUIZ_STATUS_SUCCESS){
        let data=action.payload;//----------Response from api
        const state = store.getState()
        const question =state.QuizV2Reducer.question;
        const allQuestion =state.QuizV2Reducer.allQuestion;
        if(data){
            setTimeout(()=>{
                dispatch({type:actionTypes.CHECK_QUIZ_STATUS})
            },2000)
            dispatch(trigger.updateQuizStatus(data))
            if(data.show_screen==="leaderboard"){
                dispatch(trigger.getParticpants())
                if(allQuestion==null){
                    dispatch({type:actionTypes.FETCH_ALL_QUESTION})
                }
                if(localStorage.HostJwtToken!==undefined && history.location.pathname!=='/host' ){
                    history.push('/host')
                }else{
                    history.push('/lobby')
                    dispatch({type:actionTypes.SHOW_LEADERBOARD_SUCCESS})
                }
            }
            if(data.show_screen==="question" && getQuestionId().question_id!==null){          
                if(question==null){
                    dispatch({type:actionTypes.GET_CURRENT_QUESTION})
                }
                setTimeout(() => {
                    dispatch({type:actionTypes.GET_REMAINING_TIME})
                }, 3000);
            }
            if(data.show_screen==="answer"){
                if(localStorage.HostJwtToken!==undefined){
                    history.push('/timeup')
                }else{
                    dispatch({type:actionTypes.SHOW_ANSWER_SCREEN})
                }
            }
        }
    }
}


//-- Handling the Quiz Status exception----------
export const checkQuizStatusFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.CHECK_QUIZ_STATUS_FAIL){
        setTimeout(()=>{
            dispatch({type:actionTypes.CHECK_QUIZ_STATUS})
        },2000)
    }
}

// If Quiz Status is "Leaderboard" then fetching all the participants
export const getParticipantList=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.GET_PARTICIPANT_LIST){
        let data=getQuizId()
        let onSuccess=actionTypes.GET_PARTICIPANT_LIST_SUCCESS;
        let onFail=actionTypes.GET_PARTICIPANT_LIST_FAIL;
        dispatch(apiRequest('GET',API.LEADERBOARD,data,onSuccess,onFail,null,null))
    }
}


//Getting the list of participant here
export const getParticipantListSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.GET_PARTICIPANT_LIST_SUCCESS){
        let data=action.payload;
        const state=store.getState()
        const id=state.QuizV2Reducer.isWinner
        const name=state.ParticipantAuthReducer.data?.participant
        const isLastQuestion=state.QuizV2Reducer.isLastQuestion
        if(data.length>0){
            // Updating participant list in redux
            dispatch(trigger.updateParticipantList(data))
            if(data[0].name==name && isLastQuestion){
                dispatch(trigger.isWinner())
            }
        }

    }
}


// Handling the exception while getting the particpant list
export const getParticipantListFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.GET_PARTICIPANT_LIST_FAIL){
        let data=action.payload;
        if(data){
            // updating participant list error in redux
            //
            //
        }


    }
}



// To Start Next Question

export const startNextQuestion = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SHOW_NEXT_QUESTION) {
        let data = getQuizId();
        let onSuccess = actionTypes.SHOW_NEXT_QUESTION_SUCCESS;
        let onFail = actionTypes.SHOW_NEXT_QUESTION_FAIL;
        dispatch(apiRequest('POST', API.NEXT_QUESTION, null, onSuccess, onFail, data, null))
        dispatch(trigger.startNextQuestionLoader())
    }
}

// On success of Start Next Question--
export const startNextQuestionSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.SHOW_NEXT_QUESTION_SUCCESS){
        let data=action.payload //----------Response from api
        if(data){

        }
        dispatch(trigger.stopNextQuestionLoader())
    }
}

// On failure of Start Next Question---handling exception
export const startNextQuestionFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.SHOW_NEXT_QUESTION_FAIL){
        let data=action.payload;
        if(data){

        }

        dispatch(trigger.stopNextQuestionLoader())
    }
}


// If show_screen=="question" then fetching the current question
// export const getCurrentQuestion=({dispatch})=>next=>action=>{
//     next(action)
//     if(action.type===actionTypes.GET_CURRENT_QUESTION){
//         let data=getQuestionId()
//         let onSuccess=actionTypes.GET_CURRENT_QUESTION_SUCCESS;
//         let onFail=actionTypes.GET_CURRENT_QUESTION_FAIL;
//         dispatch(apiRequest('GET',API.QUESTION,data,onSuccess,onFail,null,null))
//         dispatch({type:actionTypes.GET_CURRENT_QUESTION_START})
//     }
// }
//author: Himanshu Barmola
export const getCurrentQuestion=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.GET_CURRENT_QUESTION){
        const state = store.getState()
        const questionId=getQuestionId()
        const question =state.QuizV2Reducer.question;
        const allQuestion =state.QuizV2Reducer.allQuestion;
        if(allQuestion!==null){
            //Fetching current Question from All of the Question
            let currentQuestion=allQuestion.filter((item)=>{return item.id === questionId.question_id})[0]
            dispatch(trigger.updateCurrentQuestion(currentQuestion))

            console.log("Current Question from All Question: ",currentQuestion)
            
            dispatch({type:actionTypes.UPDATE_GET_REMAINING_TIME_SUCCESS,payload:{data:currentQuestion.time_remaining}})
            // Checking if it is the last question

        //     if(isAuthenticated('host') && history.location.pathname!=="/host-quiz"){
        //         console.log("Redirecting Host to Quiz")
        //        history.push('/host-quiz')     
        //    }
        //    // If it is Participant then navigating him to a Seprate Screen where he can select answers
        //    if(isAuthenticated('part') && history.location.pathname=="/lobby"){
        //        console.log("Redirecting Participant to Quiz")
        //        history.push('/participant-quiz')
        //    }

            if(currentQuestion.is_last_question){
                dispatch(trigger.isLastQuestion(true))
            }
                  // If it is host then navigating him to a separte screen where he can't select answers
         
        }

    }
}

//If Question Fetched then Showing the Question to the Participant and Host
export const getCurrentQuestionSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.GET_CURRENT_QUESTION_SUCCESS){
        let data=action.payload;
        if(data){
            console.log("Data in Current Question: ",data)
            //Saving the question in redux
            /// If question is null in redux
          
            dispatch(trigger.updateCurrentQuestion(data[0]))
                if(data[0].is_last_question){
                    dispatch(trigger.isLastQuestion(data[0].is_last_question))
                }
            let correct = data[0].answer.filter((item) => { return item.is_correct == true })
            dispatch({type:actionTypes.SAVE_CORRECT_ANSWER,payload:{correct}})
             
             // It it is host then navigating him to a separte screen where he can't select answers
             if(isAuthenticated('host') && history.location.pathname!=="/host-quiz"){
                 console.log("Redirecting Host to Quiz")
                history.push('/host-quiz')     
            }
            // If it is Participant then navigating him to a Seprate Screen where he can select answers
            if(isAuthenticated('part') && history.location.pathname=="/lobby"){
                console.log("Redirecting Participant to Quiz")
                history.push('/participant-quiz')
            }
           
        }
        dispatch({type:actionTypes.GET_CURRENT_QUESTION_FINISH})
    }
}


// If Question Fetch fail then handling the exception
export const getCurrentQuestionFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.GET_CURRENT_QUESTION_FAIL){
        let data=action.payload;
        if(data){

        }
        dispatch({type:actionTypes.GET_CURRENT_QUESTION_FINISH})

    }
}



// If Host wants to show the Leaderboard

export const showLeaderBoard = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SHOW_LEADERBOARD) {
        let data =getQuizId();
        let onSuccess = actionTypes.SHOW_LEADERBOARD_SUCCESS;
        let onFail = actionTypes.SHOW_LEADERBOARD_FAIL;

        dispatch(apiRequest('POST', API.SHOW_LEADERBOARD, null, onSuccess, onFail, data, null))
        dispatch({ type: actionTypes.SHOW_LEADERBOARD_START })
    }
}

// On Calling the show_leaderboard api successfully
export const showLeaderBoardSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SHOW_LEADERBOARD_SUCCESS) {
        let data = action.payload;
        if (data) {

        }
        dispatch({ type: actionTypes.SHOW_LEADERBOARD_FINISH })

    }
}

// Handling the exception of show_leaderboard api
export const showLeaderBoardFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SHOW_LEADERBOARD_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch({ type: actionTypes.HIDE_SHOW_LEADERBOARD_FAIL })
            // dispatch(updateShowLeaderBoard(data))
        }
        dispatch({ type: actionTypes.SHOW_LEADERBOARD_FINISH })

    }
}

// TO end the Quiz
export const endQuiz=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.END_QUIZ){
        let data = getQuizId()
        let onSuccess=actionTypes.END_QUIZ_SUCCESS;
        let onFail=actionTypes.END_QUIZ_FAIL;
        dispatch(apiRequest('POST',API.END_QUIZ,null,onSuccess,onFail,data,null))
        dispatch({type:actionTypes.HOST_AUTH_START})
    }
}


// Dispatching the logout fucntion after Ending Quiz Successfully
export const endQuizSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.END_QUIZ_SUCCESS){
         let data=action.payload;
         if(data){
            dispatch({type:actionTypes.LOGOUT})

         }
         dispatch({type:actionTypes.HOST_AUTH_FINISH})

    }
}

export const endQuizFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.END_QUIZ_FAIL){
        let data=action.payload;
        if(data){

        }
        dispatch({type:actionTypes.HOST_AUTH_FINISH})
    }
}

// To Logout host and participant after quiz ended
export const logout=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.LOGOUT){
        localStorage.removeItem("HostJwtToken");
        localStorage.setItem('ParticipantAuthenticated',false)
        localStorage.clear()

        dispatch({type:actionTypes.REMOVE_TOKEN})
        // if(history.location.pathname=="/host"){
        //     history.push('/host-login')
        // }else{
        //     history.push('/')
        // }
        setTimeout(()=>{
            Alert.warning('The Quiz has been ended or not yet been started by Host, You will be redirecting to the login page,',10000)
            window.location.reload()
        },2000)
    }
}



// Submitting Answer---------
export const submitAnswer = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SUBMIT_ANSWER) {
        let data = action.payload.data;
        let onSuccess = actionTypes.SUBMIT_ANSWER_SUCCESS;
        let onFail = actionTypes.SUBMIT_ANSWER_FAIL;
        dispatch(apiRequest('POST', API.SUBMIT_ANSWER, null, onSuccess, onFail, data, null))
        dispatch({ type: actionTypes.SUBMIT_ANSWER_START })
    }
}

export const submitAnswerFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SUBMIT_ANSWER_FAIL) {
        let data = action.payload;
       if(data && !data?.msg?.toLowerCase()?.includes('duplicate')){
        Alert.error(data?.msg,3000)
        dispatch(trigger.updateSubmitAnswerFail(data))
       }
    }
}

// Submit Answer Success-------------------------
export const submitAnswerSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SUBMIT_ANSWER_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(trigger.updateSubmitAnswerSuccess(data))
        }
    }
}


// Calling show_answer_screen api when show_screen==="answer"
export const showAnswerScreen=({dispatch})=>next=>action=>{
    next(action)
    if(action.type==actionTypes.SHOW_ANSWER_SCREEN){
            let data={
                ...getParticipantId(),
                ...getQuestionId()
            }
            let onSuccess=actionTypes.SHOW_ANSWER_SCREEN_SUCCESS;
            let onFail=actionTypes.SHOW_ANSWER_SCREEN_FAIL;
            dispatch(apiRequest('POST',API.ANSWER,null,onSuccess,onFail,data,null))
    }
}

// on Successfully calling the show_answer_screen api
export const showAnswerScreenSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.SHOW_ANSWER_SCREEN_SUCCESS){
        let data=action.payload;
        if(data){
            dispatch(trigger.updateShowAnswerScreen(data))
            const state = store.getState()
            const correctAnswer = state.QuizV2Reducer.correctAnswer
            console.log("Correct Answer: ", correctAnswer)
            dispatch(trigger.updateSubmitAnswerSuccess(data))
            // saving correct asnwer of question in redux
            let correct = data.question.answer.filter((item) => { return item.is_correct == true })
            if(correct.length>0){
                correct.push(data.question.answer_description)
            }
            else{
                let datas={
                    option:data.question.descriptive_correct_answer
                }
                correct[0]=datas;
                correct[1]=data.question.answer_description
            }
            dispatch({type:actionTypes.SAVE_CORRECT_ANSWER,payload:{correct}})

            console.log("Data in submit: ", data)
            // if (data.answered) {
            //     history.push('/no-answer')
            // }
            // else if (data.answered && data.correct) {
            //     history.push('/correct-answer')
            // }
            // else if (data.answered && !data.correct) {  
            //     history.push('/wrong-answer')
            // }
            if (data.answered){
                if(data.correct){
                    history.push('/correct-answer')
                }else{
                    history.push('/wrong-answer')
                }
            }else{
                history.push('/no-answer')
            }

            



            // getting the success resposne of answer screen
        }
    }
}

// handling the exception of show_answer_screen api
export const showAnswerScreenFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.SHOW_ANSWER_SCREEN_FAIL){
        let data=action.payload;
        if(data){
            // getting the error resposne of answer screen
        }
    }
}

//if host tries to cancel the quiz

export const cancelQuiz=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.CANCEL_QUIZ){
        let data=getQuizId()
        let onSuccess=actionTypes.CANCEL_QUIZ_SUCCESS;
        let onFail=actionTypes.CANCEL_QUIZ_FAIL;
        dispatch(apiRequest('POST',API.CANCEL_QUIZ,null,onSuccess,onFail,data,null))
        dispatch({type:actionTypes.CANCEL_QUIZ_START})
    }
}

export const cancelQuizSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.CANCEL_QUIZ_SUCCESS){
        let data=action.payload;
        if(data){
            // getting the error resposne of answer screen
        }
        dispatch({type:actionTypes.CANCEL_QUIZ_FINISH})
    }
}

export const cancelQuizFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.CANCEL_QUIZ_FAIL){
        let data=action.payload;
        if(data){
            // getting the error resposne of answer screen
        }
        dispatch({type:actionTypes.CANCEL_QUIZ_FINISH})
    }
}

export const getRemainingTime=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.GET_REMAINING_TIME){
        let data=getQuestionId();
        let onSuccess = actionTypes.GET_REMAINING_TIME_SUCCESS;
        let onFail = actionTypes.GET_REMAINING_TIME_FAIL;

        dispatch(apiRequest('GET',API.TIME_REMAINING,data,onSuccess,onFail,null,null))
    }
}


//hanlding remaining time succes response
export const getRemainingTimeSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.GET_REMAINING_TIME_SUCCESS){
        let data=action.payload;
        const state = store.getState()
        const quizStatus =state.QuizV2Reducer.quizStatus;
        if(data){
            dispatch({type:actionTypes.UPDATE_GET_REMAINING_TIME_SUCCESS,payload:{data:data.remaining}})
            if(quizStatus.show_screen=="question"){
                setTimeout(()=>{
                    dispatch({type:actionTypes.GET_REMAINING_TIME})
                },3000)
            }
        }
    }
}


//handling get remaining time exception
export const getRemainingTimeFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.GET_REMAINING_TIME_FAIL){
        let data=action.payload;
        const state = store.getState()
        const quizSatus =state.QuizV2Reducer.quizStatus;
        if(data){
            if(quizSatus.show_screen=="question" ){
                setTimeout(()=>{
                    dispatch({type:actionTypes.GET_REMAINING_TIME})
                },3000)
            }
        }
    }
}


//fetching all question at once------------------------------------ start--------------------
export const fetchAllQuestion=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.FETCH_ALL_QUESTION){
        let data= getQuizId();
        let onSuccess=actionTypes.FETCH_ALL_QUESTION_SUCCESS;
        let onFail=actionTypes.FETCH_ALL_QUESTION_FAIL;
        dispatch(apiRequest('GET',API.ALL_QUESTION,data,onSuccess,onFail,null,null))
    }
}

export const fetchAllQuestionSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.FETCH_ALL_QUESTION_SUCCESS){
        let data=action.payload;
        if(data){
            dispatch(trigger.updateAllQuestionSuccess(data))
            dispatch({type:actionTypes.GET_CURRENT_QUESTION})
        }
    }
}

export const fetchAllQuestionFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.FETCH_ALL_QUESTION_FAIL){
        let data = action.payload;
        if(data){
            console.log("Fetch All Question Error: ",data)
        }
    }
}
//fetching all question at once---------------------------------------end-----------------


export const quizV2mdl=[
    startNewInstance,
    startNewInstanceFail,
    startNewInstanceSuccess,
    checkQuizOngoing,
    checkQuizOngoingSuccess,
    checkQuizOngoingFail,
    // checkQuizStatus,
    checkQuizStatusSuccess,
    checkQuizStatusFail,
    startNextQuestion, // To Start Next Question
    startNextQuestionSuccess,// On success of Start Next Question--
    startNextQuestionFail,// On failure of Start Next Question---handling exception
    getParticipantList,// If Quiz Status is "Leaderboard" then fetching all the participants
    getParticipantListSuccess,//Getting the list of participant here
    getParticipantListFail,// Handling the exception while getting the particpant list
    showLeaderBoard,// If Host wants to show the Leaderboard
    showLeaderBoardSuccess,// On Calling the show_leaderboard api successfully
    showLeaderBoardFail,// Handling the exception of show_leaderboard api
    getCurrentQuestion,
    // getCurrentQuestionSuccess,
    // getCurrentQuestionFail,
    endQuiz,
    endQuizSuccess,
    endQuizFail,
    logout,
    submitAnswer,
    submitAnswerSuccess,
    submitAnswerFail,
    showAnswerScreen,
    showAnswerScreenFail,
    showAnswerScreenSuccess,
    cancelQuiz,
    cancelQuizSuccess,
    cancelQuizFail,
    getRemainingTime,
    getRemainingTimeSuccess,
    getRemainingTimeFail,
    fetchAllQuestion,
    fetchAllQuestionSuccess,
    fetchAllQuestionFail
]   