import axios from "axios"
import * as API from "../api/api"

// const AUTH_TOKEN="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjc1NTAyNTMwLCJqdGkiOiI0ZjMxMzk5YzcyMGQ0NGJjOThjNmM0MDIyZjg0YzY1MCIsInVzZXJfaWQiOjN9.Lc0HZMdkh8cpqwBRgTAt0QaEu6UxtmMtdZD37DPiinY"

// axios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;

export const apiRequest=async(method,api,payload,param)=>{
    console.log("Payload: ",payload)
    const {data} =await axios({
        baseURL:API.BASE_URI,
        url:api,
        method,
        params:{
            ...param
        },
        data:payload
    })
    return data

}