import React, { forwardRef, useState,Fragment,useEffect } from 'react'
import FlipMove from 'react-flip-move';
import { photos } from "../constant/images"
import PropTypes from "prop-types"
import classNames from "classnames"
export default function LeaderBoard({ participants, participantName, totalParticipant, user,quiz }) {

   
    const getRank=(score,idx)=>{
        if(score>0 && idx>0 && score==participants[idx-1].score){
            return idx
        }else{
            return idx+1
        }
    }



    const ShowParticipant = forwardRef((props, ref) => {
        const divWrapper=classNames('leaderboard__participant',{
            "highlight":user && props.name == participantName,
            "winner":user && props.ids==0 && props.score>0 && quiz!==null && quiz.question_no==null
        })

        return (
            <div className={divWrapper} ref={ref} >
                <div className={'leaderboard__participant__rank'}>
                    <p>{getRank(props.score,props.ids)}.</p>
                </div>
                <div >
                    <p>{props.name}</p>
                </div>
                <div className="leaderboard__participant__points" >
                {(props.ids == 0 || props.ids ==1 || props.ids==2 ) && props.score > 0 &&
                <div className="img"  >
                            <img 
                        src={user&&quiz!==null && quiz.question_no==null?photos.cong3:photos.trophy} 
                        className={user &&quiz!==null && quiz.show_leaderboard && quiz.question_no==null?"winner":"normal"}
                        />
                </div>
                        }
                    <p>{props.score} <span>pts</span></p>
                </div>
            </div>
        )
    })

    return (
        <div className="leaderboard">
            {participants.length>0&&<Fragment>
            <h4>Leaderboard</h4>
            <FlipMove className="leaderboard__BOX" >
                {participants.map((participant, index) => (
                    <ShowParticipant key={participant.id} ids={index}  {...participant} />
                ))}
            </FlipMove>
            </Fragment>}      
        </div>
    )
}


LeaderBoard.propTypes={
    participants:PropTypes.array.isRequired,
    participantName:PropTypes.string,
    user:PropTypes.bool,
    quiz:PropTypes.object
}
