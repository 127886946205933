//@author:Himanshu Barmola
//Project: Mern-Authentication


//This middleware handles all login functionality

import * as actionTypes from "../actionTypes"
import { authApi, postApiRequest, apiRequest } from "../actions/api"
import * as API from "../../api/api"
import { handleSuccessLogin, handleFailLogin, logoutSuccess, loaderStart, loaderFinish,setHostCurrentUser } from "../actions/hostauth"
import history from "../../helpers/history"
import jwt_decode from "jwt-decode";
import * as trigger from "../actions/quizV2"
// import jwt_decode from "jwt-decode"
import { Alert } from 'rsuite'
import { store } from "../store/index"
let hostPin;
export const handleLogin = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.HOST_AUTH) {
        let data = action.payload.data;

        hostPin = data.pin
        let onSuccess = actionTypes.HOST_AUTH_SUCCESS;
        let onError = actionTypes.HOST_AUTH_FAIL
        dispatch(loaderStart())
        dispatch(apiRequest('POST', API.HOST_LOGIN, null, onSuccess, onError, data, null))
    }
}

export const handleLoginSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.HOST_AUTH_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch({ type: actionTypes.SAVE_HOST_PIN, payload: hostPin })
            localStorage.setItem("HostJwtToken", data.access);  
            const decoded = jwt_decode(data.access);
            dispatch(handleSuccessLogin(decoded))
            dispatch(setHostCurrentUser(data))
            if(!data.is_new_instance){
// show popup to host to confrim whether he want to start new insatance of the quiz or not
                dispatch(trigger.showNewInstancePopup())
            }
            history.push('/host') //Navigating to the dashboard after successful login
        }
        dispatch(loaderFinish())
    }
}

export const handleLoginFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.HOST_AUTH_FAIL) {
        let data = action.payload;
        console.log("Auth Fail: ", data)
        dispatch(loaderFinish())

        if (data) {
            let error;
            console.log("Filtering Error: ",Object.values(data))
            if(data.hasOwnProperty('non_field_errors')){
                error=Object.values(data)
            }else{
                error=Object.entries(data)
            }
            dispatch(handleFailLogin(error))
            // Alert.error(data.non_field_errors[0], 2000)
        }

    }
}


// export const logout = ({ dispatch }) => next => action => {
//     next(action)
//     if (action.type === actionTypes.AUTH_TOKEN_REMOVE) {
//         dispatch(logoutSuccess())
//         history.push('/')
//     }
// }


// export const checkTokenVailid = ({ dispatch }) => next => action => {
//     next(action)
//     if (action.type === actionTypes.CHECK_TOKEN_VAILID) {
//         const state = store.getState();
//         const token = state.AuthReducer.token;
//         if (token) {
//             let currentTime = Date.now() / 1000;
//             const decoded = jwt_decode(token)
//             console.log("Checking Authorization....")
//             if (decoded.exp < currentTime) {
//                 console.log("Loggin You out. Please login again")
//                 dispatch(logoutSuccess())
//                 history.push('/login')
//             } else {
//                 console.log("Authorized....")

//             }
//         }
//         else {
//             dispatch(logoutSuccess())
//             history.push('/')
//         }

//     }
// }


export const hostAuthMdl = [
    handleLogin,
    handleLoginFail,
    handleLoginSuccess,
    // checkTokenVailid,
    // logout
]