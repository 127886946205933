//To request api
export const API_REQUEST = "API_REQUEST";
export const POST_API_REQUEST = "POST_API_REQUEST";
export const AUTH_API_REQUEST = "AUTH_API_REQUEST";

export const INTERNET_STABLE="INTERNET_STABLE"
export const INTERNET_UNSTABLE="INTERNET_UNSTABLE"

// Host Login
export const HOST_AUTH = "HOST_AUTH"
export const HOST_AUTH_START = "HOST_AUTH_START"
export const HOST_AUTH_FINISH = "HOST_AUTH_FINISH"
export const HOST_AUTH_SUCCESS = "HOST_AUTH_SUCCESS"
export const HOST_AUTH_FAIL = "HOST_AUTH_FAIL"
export const UPDATE_HOST_AUTH_SUCCESS = "UPDATE_HOST_AUTH_SUCCESS"
export const UPDATE_HOST_AUTH_FAIL = "UPDATE_HOST_AUTH_FAIL"
export const LOGOUT="LOGOUT"
export const REMOVE_TOKEN = "REMOVE_HOST_TOKEN"
export const SAVE_HOST_PIN = "SAVE_HOST_PIN"

// Participant Join

export const PARTICIPANT_AUTH = "PARTICIPATE_AUTH"
export const PARTICIPANT_AUTH_START = "PARTICIPATE_AUTH_START"
export const PARTICIPANT_AUTH_FINISH = "PARTICIPATE_AUTH_FINISH"
export const PARTICIPANT_AUTH_SUCCESS = "PARTICIPATE_AUTH_SUCCESS"
export const PARTICIPANT_AUTH_FAIL = "PARTICIPATE_AUTH_FAIL"
export const UPDATE_PARTICIPANT_AUTH_FAIL = "UPDATE_PARTICIPATE_AUTH_FAIL"
export const UPDATE_PARTICIPANT_AUTH_SUCCESS = "UPDATE_PARTICIPATE_AUTH_SUCCESS"
export const SAVE_PARTICIPATE_PIN = "SAVE_PARTICIPATE_PIN"

//CHECK WHETHER QUIZ START OR NOT;

export const CHECK_QUIZ = "CHECK_QUIZ";
export const CQ_START = "CQ_START"
export const CQ_FINISH = "CQ_FINISH"
export const CQ_SUCCESS = "CQ_SUCCESS"
export const CQ_FAIL = "CQ_FAIL"
export const UPDATE_CQ_FAIL = "UPDATE_CQ_FAIL"
export const UPDATE_CQ_SUCCESS = "UPDATE_CQ_SUCCESS"


//GET CURRENT QUESTION

export const GCQ_START = "GCQ_START"
export const GCQ_FINISH = "GCQ_FINISH"
export const GCQ_SUCCESS = "GCQ_SUCCESS"
export const GCQ_FAIL = "GCQ_FAIL"
export const UPDATE_GCQ_FAIL = "UPDATE_GCQ_FAIL"
export const UPDATE_GCQ_SUCCESS = "UPDATE_GCQ_SUCCESS"




export const GPL_START = "GPL_START";
export const GPL_FINISH = "GPL_FINISH";
export const GPL_SUCCESS = "GPL_SUCCESS"
export const GPL_FAIL = "GPL_FAIL"
export const UPDATE_GPL_FAIL = "UPDATE_GPL_FAIL";
export const UPDATE_GPL_SUCCESS = "UPDATE_GPL_SUCCESS";

//START QUIZ

export const START_QUIZ = "START_QUIZ";
export const START_QUIZ_START = "START_QUIZ_START";
export const START_QUIZ_FINISH = "START_QUIZ_FINISH"
export const START_QUIZ_SUCCESS = "START_QUIZ_SUCCESS"
export const START_QUIZ_FAIL = "START_QUIZ_FAIL"
export const UPDATE_START_QUIZ_FAIL = "UPDATE_START_QUIZ_FAIL"
export const UPDATE_START_QUIZ_SUCCESS = "UPDATE_START_QUIZ_SUCCESS"


// SUBMIT ANSWER
export const SUBMIT_ANSWER = "SUBMIT_ANSWER";
export const SUBMIT_ANSWER_START = "SUBMIT_ANSWER_START"
export const SUBMIT_ANSWER_FINISH = "SUBMIT_ANSWER_FINISH"
export const SUBMIT_ANSWER_SUCCESS = "SUBMIT_ANSWER_SUCCESS"
export const SUBMIT_ANSWER_FAIL = "SUBMIT_ANSWER_FAIL"
export const UPDATE_SUBMIT_ANSWER_FAIL = "UPDATE_SUBMIT_ANSWER_FAIL"
export const UPDATE_SUBMIT_ANSWER_SUCCESS = "UPDATE_SUBMIT_ANSWER_SUCCESS"


// SHOW LEADERBOARD

export const SHOW_LEADERBOARD = "SHOW_LEADERBOARD";
export const SHOW_LEADERBOARD_START = "SHOW_LEADERBOARD_START";
export const SHOW_LEADERBOARD_FINISH = "SHOW_LEADERBOARD_FINISH";
export const SHOW_LEADERBOARD_SUCCESS = "SHOW_LEADERBOARD_SUCCESS";
export const SHOW_LEADERBOARD_FAIL = "SHOW_LEADERBOARD_FAIL";
export const HIDE_SHOW_LEADERBOARD_FAIL = "HIDE_SHOW_LEADERBOARD_FAIL";
export const UPDATE_SHOW_LEADERBOARD_SUCCESS = "UPDATE_SHOW_LEADERBOARD_SUCCESS";



export const SHOW_NEXT_QUESTION = "SHOW_NEXT_QUESTION"
export const SHOW_NEXT_QUESTION_START = "SHOW_NEXT_QUESTION_START"
export const SHOW_NEXT_QUESTION_FINISH = "SHOW_NEXT_QUESTION_FINISH"
export const SHOW_NEXT_QUESTION_SUCCESS = "SHOW_NEXT_QUESTION_SUCCESS"
export const SHOW_NEXT_QUESTION_FAIL = "SHOW_NEXT_QUESTION_SUCCESS"
export const UPDATE_SHOW_NEXT_QUESTION_SUCCESS = "UPDATE_SHOW_NEXT_QUESTION_SUCCESS"
export const UPDATE_SHOW_NEXT_QUESTION_FAIL = "UPDATE_SHOW_NEXT_QUESTION_FAIL"


export const NO_ANSWER_GIVEN = "NO_ANSWER_GIVEN";
export const ANSWER_IS_CORRECT = "ANSWER_IS_CORRECT";
export const ANSWER_IS_WRONG = "ANSWER_IS_WRONG";
export const ANSWER_ALREADY_GIVEN="ANSWER_ALREADY_GIVEN"


export const SET_HOST_CURRENT_USER= "SET_HOST_CURRENT_USER"
export const SET_PART_CURRENT_USER="SET_PART_CURRENT_USER"



export const END_QUIZ="END_QUIZ";
export const END_QUIZ_START="END_QUIZ_START";
export const END_QUIZ_FINISH="END_QUIZ_FINISH";
export const END_QUIZ_SUCCESS="END_QUIZ_SUCCESS";
export const END_QUIZ_FAIL="END_QUIZ_FAIL";
export const UPDATE_END_QUIZ_SUCCESS="UPDATEEND_QUIZ_SUCCESS";
export const UPDATE_END_QUIZ_FAIL="UPDATE_END_QUIZ_FAIL";


// Quiz version 2------------------------------------------------------------------------------------------------------

export const SHOW_NEW_INSTANCE_POPUP="SHOW_NEW_INSTANCE_POPUP"
export const HIDE_NEW_INSTANCE_POPUP="HIDE_NEW_INSTANCE_POPUP"

export const START_NEW_INSTANCE="START_NEW_INSTANCE";
export const START_PREVIOUS_INSTANCE="START_PREVIOUS_INSTANCE"


export const START_NEW_INSTANCE_START="START_NEW_INSTANCE_START"
export const START_NEW_INSTANCE_FINISH="START_NEW_INSTANCE_FINISH"
export const START_NEW_INSTANCE_SUCCESS="START_NEW_INSTANCE_SUCCESS"
export const START_NEW_INSTANCE_FAIL="START_NEW_INSTANCE_FAIL"
export const UPDATE_START_NEW_INSTANCE_SUCCESS="UPDATE_START_NEW_INSTANCE_SUCCESS"
export const UPDATE_START_NEW_INSTANCE_FAIL="UPDATE_START_NEW_INSTANCE_FAIL"


// Check Quiz Ongoing

export const CHECK_QUIZ_ONGOING="CHECK_QUIZ_ONGOING";
export const CHECK_QUIZ_ONGOING_START="CHECK_QUIZ_ONGOIN_START"
export const CHECK_QUIZ_ONGOING_FINISH="CHECK_QUIZ_ONGOIN_FINISH"
export const CHECK_QUIZ_ONGOING_SUCCESS="CHECK_QUIZ_ONGOIN_SUCCESS"
export const CHECK_QUIZ_ONGOING_FAIL="CHECK_QUIZ_ONGOIN_FAIL"
export const UPDATE_CHECK_QUIZ_ONGOING_FAIL="UPDATE_CHECK_QUIZ_ONGOIN_FAIL"
export const UPDATE_CHECK_QUIZ_ONGOING_SUCCESS="UPDATE_CHECK_QUIZ_ONGOIN_SUCCESS"


//Check Quiz Status

export const CHECK_QUIZ_STATUS="CHECK_QUIZ_STATUS";
export const CHECK_QUIZ_STATUS_START="CHECK_QUIZ_STATUS_START";
export const CHECK_QUIZ_STATUS_FINSH="CHECK_QUIZ_STATUS_FINISH";
export const CHECK_QUIZ_STATUS_SUCCESS='CHECK_QUIZ_STATUS_SUCCESS';
export const CHECK_QUIZ_STATUS_FAIL='CHECK_QUIZ_STATUS_FAIL';
export const UPDATE_CHECK_QUIZ_STATUS_FAIL='UPDATE_CHECK_QUIZ_STATUS_FAIL';
export const UPDATE_CHECK_QUIZ_STATUS_SUCCESS='UPDATE_CHECK_QUIZ_STATUS_SUCCESS';


//GET PARTICIPANT LIST

export const GET_PARTICIPANT_LIST =       "GET_PARTICIPANT_LIST";
export const GET_PARTICIPANT_LIST_START = "GET_PARTICIPANT_LIST_START";
export const GET_PARTICIPANT_LIST_FINISH = "GET_PARTICIPANT_LIST_FINISH";
export const GET_PARTICIPANT_LIST_SUCCESS = "GET_PARTICIPANT_LIST_SUCCESS";
export const GET_PARTICIPANT_LIST_FAIL = "GET_PARTICIPANT_LIST_FAIL";
export const UPDATE_GET_PARTICIPANT_LIST_FAIL = "UPDATE_GET_PARTICIPANT_LIST_FAIL";
export const UPDATE_GET_PARTICIPANT_LIST_SUCCESS = "UPDATE_GET_PARTICIPANT_LIST_SUCCESS";


//GET CURRENT QUESTION

export const GET_CURRENT_QUESTION= "GET_CURRENT_QUESTION";
export const GET_CURRENT_QUESTION_START= "GET_CURRENT_QUESTION_START";
export const GET_CURRENT_QUESTION_FINISH= "GET_CURRENT_QUESTION_FINISH";
export const GET_CURRENT_QUESTION_SUCCESS= "GET_CURRENT_QUESTION_SUCCESS";
export const GET_CURRENT_QUESTION_FAIL= "GET_CURRENT_QUESTION_FAIL";
export const UPDATE_GET_CURRENT_QUESTION_FAIL= "UPDATE_GET_CURRENT_QUESTION_FAIL";
export const UPDATE_GET_CURRENT_QUESTION_SUCCESS= "UPDATE_GET_CURRENT_QUESTION_SUCCESS";


//SAVE CURRENT ANSWER
export const SAVE_CORRECT_ANSWER = "SAVE_CORRECT_ANSWER"



//ANSWER
export const SHOW_ANSWER_SCREEN= "SHOW_ANSWER_SCREEN"
export const SHOW_ANSWER_SCREEN_SUCCESS="SHOW_ANSWER_SCREEN_SUCCESS"
export const SHOW_ANSWER_SCREEN_FAIL="SHOW_ANSWER_SCREEN_FAIL"
export const UPDATE_SHOW_ANSWER_SCREEN_FAIL="UPDATE_SHOW_ANSWER_SCREEN_FAIL"
export const UPDATE_SHOW_ANSWER_SCREEN_SUCCESS="UPDATE_SHOW_ANSWER_SCREEN_SUCCESS"
export const SHOW_ANSWER_SCREEN_START="SHOW_ANSWER_SCREEN_START"


//CANCEL QUIZ
export const CANCEL_QUIZ= "CANCEL_QUIZ";
export const CANCEL_QUIZ_START="CANCEL_QUIZ_START"
export const CANCEL_QUIZ_FINISH="CANCEL_QUIZ_FINISH"
export const CANCEL_QUIZ_SUCCESS="CANCEL_QUIZ_SUCCESS"
export const CANCEL_QUIZ_FAIL="CANCEL_QUIZ_FAIL"


//to check whether it is last question or not

export const IS_LAST_QUESTION= "IS_LAST_QUESTION";


// To check whether the participant is winner or not

export const IS_WINNER="IS_WINNER";

export const IS_TIMER_UP= "IS_TIMER_UP"


// getting remaining time
export const GET_REMAINING_TIME="GET_REMAINING_TIME"
export const GET_REMAINING_TIME_SUCCESS="GET_REMAINING_TIME_SUCCESS"
export const GET_REMAINING_TIME_FAIL="GET_REMAINING_TIME_FAIL"
export const UPDATE_GET_REMAINING_TIME_FAIL="UPDATE_GET_REMAINING_TIME_FAIL"
export const UPDATE_GET_REMAINING_TIME_SUCCESS="UPDATE_GET_REMAINING_TIME_SUCCESS"


//fetch all question

export const FETCH_ALL_QUESTION= "FETCH_ALL_QUESTION"
export const FETCH_ALL_QUESTION_START= "FETCH_ALL_QUESTION_START"
export const FETCH_ALL_QUESTION_FINISH= "FETCH_ALL_QUESTION_FINISH"
export const FETCH_ALL_QUESTION_SUCCESS= "FETCH_ALL_QUESTION_SUCCESS"
export const FETCH_ALL_QUESTION_FAIL= "FETCH_ALL_QUESTION_FAIL"
export const UPDATE_FETCH_ALL_QUESTION_FAIL= "UPDATE_FETCH_ALL_QUESTION_FAIL"
export const UPDATE_FETCH_ALL_QUESTION_SUCCESS= "UPDATE_FETCH_ALL_QUESTION_SUCCESS"




export const WEBSOCKET_CLIENT_CONNECTED="WEBSOCKET_CLIENT_CONNECTED"
export const WEBSOCKET_CLIENT_DISCONNECTED="WEBSOCKET_CLIENT_DISCONNECTED"