import React from 'react'
import PropTypes from "prop-types"
import Lottie from 'react-lottie';
import cong from "../data/cong.json"
import crack from "../data/crackers.json"
import {AiOutlineClose} from "react-icons/all"
export default function Modal() {

const  defaultOptions=(data)=>{
    return {
        loop:true,
        autoplay:true,
        animationData:data,
        background:"transparent",
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
    }
   
}

const closeModal=()=>{
    var modalId=document.querySelector('.modal-custom');
    modalId.classList.toggle('toggle-modal')
}


    return (
        <div className="modal-custom">
            <div className="modal-custom__overlay">
            <div className="modal-custom__body animate__animated animate__fadeIn">
                <div className="modal-custom__close-btn" onClick={closeModal} >
                <AiOutlineClose size={24} />
                </div>
                <Lottie options={defaultOptions(crack)}height={150}/>
                <Lottie options={defaultOptions(cong)}height={150} width={150}/>
                <h4 className="animate__animated animate__fadeInUp animate__delay-500ms" >You Won</h4>
            </div>
            </div>  
        </div>
    )
}






Modal.propTypes={

}