import * as actionTypes from "../actionTypes"



export const checkQuiz = (data) => ({
    type: actionTypes.CHECK_QUIZ,
    payload: { data }
})

export const getParticipantList = (data) => ({
    type: actionTypes.GET_PARTICIPANT_LIST,
    payload: { data }
})

export const startQuiz = (data) => ({
    type: actionTypes.START_QUIZ,
    payload: { data }
})


export const handleQuizUpdateSuccess = (data) => ({
    type: actionTypes.UPDATE_CQ_SUCCESS,
    payload: { data }
})

export const handleQuestionUpdateSuccess = (data) => ({
    type: actionTypes.UPDATE_GCQ_SUCCESS,
    payload: { data }
})

export const handleQuizUpdateFail = (data) => ({
    type: actionTypes.UPDATE_CQ_FAIL,
    payload: { data }
})

export const handleQuestionUpdateFail = (data) => ({
    type: actionTypes.UPDATE_GCQ_FAIL,
    payload: { data }
})


export const quizLoaderStart = () => ({
    type: actionTypes.CQ_START
})


export const quizLoaderFail = () => ({
    type: actionTypes.CQ_FINISH
})

export const questionLoaderStart = () => ({
    type: actionTypes.GCQ_START
})


export const questionLoaderFail = () => ({
    type: actionTypes.GCQ_FINISH
})

export const submitAnswer = (data) => ({
    type: actionTypes.SUBMIT_ANSWER,
    payload: { data }
})

export const updateSubmitAnswerSuccess = (data) => ({
    type: actionTypes.UPDATE_SUBMIT_ANSWER_SUCCESS,
    payload: { data }
})


export const showLeaderBoard = (data) => ({
    type: actionTypes.SHOW_LEADERBOARD,
    payload: { data }
})
export const updateShowLeaderBoard = (data) => ({
    type: actionTypes.UPDATE_SHOW_LEADERBOARD_SUCCESS,
    payload: { data }
})


export const saveAnswer = (data) => ({
    type: actionTypes.SAVE_CORRECT_ANSWER,
    payload: { data }
})


export const showNextQuestion = (data) => ({
    type: actionTypes.SHOW_NEXT_QUESTION,
    payload: { data }
})

export const udpateShowNextQuestion = (data) => ({
    type: actionTypes.UPDATE_SHOW_NEXT_QUESTION_SUCCESS,
    payload: { data }
})

export const logout=(data)=>({
    type:actionTypes.LOGOUT,
    payload:{data}
})


export const endQuiz=(data)=>({
    type:actionTypes.END_QUIZ,
    payload:{data}
})