import React, { useState, useEffect,Fragment,useRef } from 'react'
import PropTypes from 'prop-types';
import {Input,Button} from "rsuite"
import { photos } from "../../constant/images"
import classNames from "classnames";
import {motion} from 'framer-motion'

const WIDTH=window.innerWidth;

export default function Options({ options, optionType, host, timerup, saveCorrectAnswer, selected, setSelected, timerRef,questionType}) {
    const [subjectiveInput,setSubjectiveInput]=useState('')
    const inputRef=useRef()
    const submitAnswer = (correct, id) => {
        let data = {
            correct,
            answer:id?id:null,
            subjective:subjectiveInput!==''?subjectiveInput:null
        }
        timerRef.current?.getApi().pause()
        setTimeout(() => {
            setSelected(data)
        }, 500)
        var el = this;
        var par = el?.parentNode;
        var next = el?.nextSibling;
        par?.removeChild(el);
        setTimeout(function() {par?.insertBefore(el, next);}, 0)
    }

    const classWrapperText = (id,idx) => {
        return classNames('quiz-options__text animate__animated animate__fadeInUp', {
            'disable': host,
            'selected': selected !== null && selected.id == id
        })
    }
    const classWrapperImage = (id,idx) => {
        return classNames('quiz-options__image animate__animated animate__fadeInUp', {
            'disable': host,
            'selected': selected !== null && selected.id == id
        })
    }

    const isOptionLengthIsLong=(option)=>{
     let temp=   option.filter((item)=>{return item.option.length>16})
      if(temp.length>0){
          return true
      }else{
          return false
      }
    }

    const optionWrapper=classNames('quiz-options',{
        'long':optionType=='text'&& isOptionLengthIsLong(options),
        'text':optionType=='text',
        'image-ans':optionType=='image',
        'image-answer':optionType=='image' && (questionType.toLowerCase() == "image" || questionType.toLowerCase() == "audio" || questionType.toLowerCase() == 'video')
    })

    useEffect(() => {
        if (options !== null) {
            console.log("Saving Answer Options:  ....",options)
            let correct = options.filter((item) => { return item.is_correct == true })
            saveCorrectAnswer(correct)
            console.log("Saving options: ",correct)
        }
        if(optionType=='subjective'){
            document.getElementById('subject-input').focus()
        }

    }, [])

    function addHover(){
        if(optionType == 'text'){
            const optionEl = document.querySelector('.quiz-options');
            optionEl.classList.toggle('hover_effect')
        }
     
    }

    return (
        <Fragment>
           {optionType!=='subjective'? <div className={optionWrapper}    >
            {optionType == "text" && options.map((item, index) => (
                <motion.div whileTap={{scale:0.9}}>
                <div className={classWrapperText(item.id,index)} key={index} onClick={() => submitAnswer(item.is_correct, item.id)} >
                    <p>{item.option}</p>
                </div>
                </motion.div>
            ))}
            {optionType == "image" && options.map((item, index) => (
                <div className={classWrapperImage(item.id,index)} key={index} onClick={() => submitAnswer(item.is_correct, item.id)} >
                    <img src={item.image} />
                </div>
            ))}
           
        </div>:
        <div className="subjective">
         <Input componentClass="textarea" autoComplete="off"  ref={inputRef} id="subject-input" onPressEnter={() => submitAnswer(false, null)} placeholder="Press Enter to Submit" rows={2} onChange={(v)=>setSubjectiveInput(v)} block  disabled={host} />
         {WIDTH<600&&
         <Button appearance="primary" id="submit-btn" size="lg" block disabled={subjectiveInput=="" || host } onClick={() => submitAnswer(false, null)} >Submit</Button>}
        </div>}
        </Fragment>
      
    )
}


Options.propTypes = {
    options: PropTypes.array.isRequired,
    optionType: PropTypes.string.isRequired,
    host: PropTypes.bool,
    selectAnswer: PropTypes.bool,
    timerup: PropTypes.bool,
    selected: PropTypes.any,
    setSelected: PropTypes.func,
    setSubjective: PropTypes.func,
}

