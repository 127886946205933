import React, { Suspense, lazy,useEffect } from "react"
import 'rsuite/lib/styles/index.less';
import { Router } from "react-router-dom"
import history from "./helpers/history"
import Routes from "./Routes/Routes"
import { connect } from "react-redux"
import * as hostAction from "./redux/actions/hostauth"
import * as partAction from "./redux/actions/participantauth"
import * as trigger from "./redux/actions/quizV2"
import * as fetcher from "./redux/actions/api"
import 'react-h5-audio-player/lib/styles.css';
import jwt_decode from "jwt-decode";
import {Alert} from "rsuite"
import {SWRConfig} from "swr"
import WebSocketProvider from "./views/WebSocket"
import Indicator from "./views/Indicator"
import {QueryClientProvider,QueryClient} from 'react-query'
function App({setHostCurrentUser,setPartCurrentUser,_checkQuizOngoing,_fetchApi,HostQuizID,PartQuizID}) {
  // const client= new W3CWEBSOCKET('ws://http://quizapp.qilinlab.com/ws/current_screen/')

  // client.onopen=()=>{
  //   console.log("WebSocket Client Connected")
  // }

  // const QuizId=HostQuizID?.quiz_id?HostQuizID.quiz_id:PartQuizID?.quiz_id


  

  const queryClient= new QueryClient()

// Check for token
if (localStorage.HostJwtToken || localStorage.ParticipantAuthenticated) {
  // Set auth token header auth
  // setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp;
  if(localStorage.HostJwtToken!==undefined){
    const decoded = jwt_decode(localStorage.HostJwtToken);
    // Set user and isAuthenticated
    setHostCurrentUser(decoded);
    console.log("Dispatched")
    setTimeout(()=>{
      // _checkQuizOngoing()
    },1500)
  }

  if(localStorage.ParticipantAuthenticated!==undefined){
    if(JSON.parse(localStorage.ParticipantAuthenticated)==true){
      // const decoded = jwt_decode(localStorage.PartJwtToken);
      // Set user and isAuthenticated
      // setPartCurrentUser(decoded);
      setTimeout(()=>{
        // _checkQuizOngoing()
        },1500)
  
    }
  }


  




}

  return (
    <QueryClientProvider client={queryClient} >
      <div className="App">
      <Router history={history} >
        <Suspense
          fallback={<div><span></span></div>}
        >
          <Routes />
          <Indicator/>
          <WebSocketProvider />
        </Suspense>
      </Router>
    </div>
    </QueryClientProvider> 
  );
}


const mapStateToProps = (state) => {
  return {
    isInternetStable:state.QuizReducer.isInternetStable,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setHostCurrentUser:(data)=>dispatch(hostAction.setHostCurrentUser(data)),
    setPartCurrentUser:(data)=>dispatch(partAction.setPartCurrentUser(data)),
    _checkQuizOngoing:()=>dispatch(trigger.checkQuizOngoing()),
    _fetchApi:()=>dispatch(fetcher.apiRequest())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
