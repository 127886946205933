//@author:Himanshu Barmola

// This is the universal api function we can customize it anytime accoriding to our requeriment.
import * as actionTypes from "../actionTypes"
import * as API from "../../api/api"
import axios from "axios"
import { store } from "../store/index"
import setAuthToken from "../setAuthToken"
import {Alert} from "rsuite"
import useSWR from "swr"
export const api = ({ dispatch }) => next => action => {
 

    if (action.type === actionTypes.API_REQUEST) {
        const state = store.getState()
        const isInternetStable=state.QuizReducer.isInternetStable
        const { method, url, onSuccess, onError, data, param } = action.meta;

            axios({url: url, method: method,baseURL:API.BASE_URI ,params: { ...action.payload}, data: data,})
            .then(res => {
                console.log("Response: ", JSON.parse(res.request.response))
                if (res.request) {
                    dispatch({ type: onSuccess, payload: JSON.parse(res.request.response) })
                }
            })
                .catch(error => {
                    if(error){
                        console.log("Error Message: ",error.message)
                        console.log("Error Stack: ",error)
                        console.log("Error Code: ",error.code)
                        console.log("Internet Connection: ",isInternetStable)
                                         
                            if (error.response) {
                                console.log("Error Response: ", error.response)
                                console.log("Internet Connection Stable: ",isInternetStable)
                                dispatch({type:actionTypes.INTERNET_STABLE})
    
                                if (error.response.status >= 400 && error.response.status < 500) {
                                    let err
                                    if (error.response.request) {
                                        err = new XMLHttpRequest(error.response.request)
                                        console.log("JSON Parsed Error: ",err)  
                                    }
                                    try{
                                        err = JSON.parse(JSON.stringify(error.response.request.response))
                                        console.log("Try Error: ",err)
                                        dispatch({ type: onError, payload: JSON.parse(error.response.request.response) })
                                    }
                                    catch(e){
                                        console.log("Catch Error: ",e)
                                        dispatch({ type: onError, payload: JSON.stringify(error.response.request.response).split('') })
                                    }
            
                                }
                                else {
                                    console.log("Error not Processed: ", error)
                                    dispatch({type:onError,payload:{error:['Something Went Wrong']}})
                                }
                               
                            }
                         
                    }
                  
    
                })
        
    


               
    }

    return next(action)
}