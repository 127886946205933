import React,{Fragment} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux"
import App from './App';
import { store } from "./redux/store/index"
import './assets/scss/styles.css'
ReactDOM.render(
  <Fragment>
    <Provider store={store} >
      <App />
    </Provider>
  </Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
