import React, { Suspense, lazy, Fragment } from "react"
import { Switch, Route } from "react-router-dom"
import { AuthLayout, PublicLayout } from "../layout"
import { RouteLayout, ParticipantRoute, HostRoute,ProtectedRoute } from "../layout/RouteLayout"
import { connect } from "react-redux"
import {CorrectAnswer,NoAnswer,WrongAnswer} from "../components"
const HostLogin = lazy(() => import('../views/host/_HostLogin'))
const ParticipantLogin = lazy(() => import('../views/participant/_ParticipantLogin'))
const ParticipantLobby = lazy(() => import('../views/participant/_ParticipantLoby'))
const HostLobby = lazy(() => import('../views/host/_HostLobby'))
const ParticipantQuiz = lazy(() => import('../views/participant/_ParticipantQuiz'))
const HostQuiz = lazy(() => import('../views/host/_HostQuiz'))
const AfterCountDown = lazy(() => import('../views/host/_AfterCountDown'))
const Page404 = lazy(() => import('../views/404'))
const isPartAuth = JSON.parse(localStorage.getItem('ParticipantAuthenticated')) ||false



const Routes = ({isHostAuth }) => {
    return (
        <Fragment>
            <Switch>
                {(isPartAuth||isHostAuth)&&<ProtectedRoute path="/" component={ParticipantLogin} exact layout={PublicLayout} />}
                <RouteLayout exact path="/" layout={ParticipantLogin} />
                <RouteLayout path="/host-login" component={HostLogin} exact  layout={PublicLayout} />
                <ProtectedRoute path="/lobby" component={ParticipantLobby} exact layout={AuthLayout} />
                <ProtectedRoute path="/host" component={HostLobby} exact layout={AuthLayout} />
                <ProtectedRoute path="/host-quiz" component={HostQuiz} exact layout={AuthLayout} />
                <ProtectedRoute path="/timeup" component={AfterCountDown} exact layout={AuthLayout} />
                <ProtectedRoute path="/participant-quiz" component={ParticipantQuiz} exact layout={AuthLayout} />
                <ProtectedRoute path="/correct-answer" component={CorrectAnswer} exact layout={AuthLayout} />
                <ProtectedRoute path="/wrong-answer" component={WrongAnswer} exact layout={AuthLayout} />
                <ProtectedRoute path="/no-answer" component={NoAnswer} exact layout={AuthLayout} />
                <Route path="*" component={Page404} />
            </Switch>
        </Fragment>
    )
}


const mapStateToProps = state => {
    return {
        isPartAuth:state.ParticipantAuthReducer.isAuthenticated,
        isHostAuth:state.HostAuthReducer.isAuthenticated,
    }
}


export default connect(mapStateToProps, null)(Routes)