//@author:Himanshu Barmola

// Customizing the Input field for Redux-Form

import React from "react"
import { Input } from "rsuite"
const renderInput = (props) => {
    return (
        <div className={`input-field`}>
            <Input
                {...props.input}
                {...props}
                placeholder={props.placeholder}
                value={props.input.value}
                name={props.input.name}
                block="true"
                size="lg"
                className={`${props.className} ${props.meta.touched && props.meta.error != undefined ? "error-field" : undefined}`}
            />
            <p id="error-msg">{props.meta.touched ? props.meta.error : ""}</p>
        </div>
    )
}



export default renderInput