import React,{useEffect,useRef} from 'react'
import PropTypes from "prop-types"
import YouTube from "react-youtube"
export default function YoutubeQuestion({link,startTime,endTime}) {
    const playerRef=useRef()
    const config={
        height:"250",
        width:"100%",
        // playerVars:{autoplay:1}
    }

    useEffect(()=>{
        playerRef.current.internalPlayer.loadVideoById({
            videoId:link.split('=')[1],
            startSeconds:startTime,
            endSeconds:endTime
        })
        playerRef.current.onPlayerReady((e)=>{
            console.log("Player Ready: ",e)
            playerRef.current.internalPlayer.playVideo()
        })
        console.log("Start Time: ",startTime)
        console.log("End Time: ",endTime)
        console.log("Player Ref: ",playerRef.current)
    },[])

    return (
        <div >
            <YouTube ref={playerRef} videoId={link.split('=')[1]}  opts={config} />
            
        </div>
    )
}




YoutubeQuestion.propTypes={
    link:PropTypes.string.isRequired
}
