import React, { useEffect,Fragment } from 'react'
import PropTypes from "prop-types"
import { photos } from "../../constant/images"
import {Alert} from "rsuite"
import Countdown from "react-countdown";
import history from "../../helpers/history"
import {connect} from "react-redux"
 function CorrectAnswer({ correctAnswer,question}) {

    useEffect(() => {

    }, [])
    return (
        <div className="quiz-result correct">
            <div className="container" >
                <img src={photos.correct} />
                <h4>Correct Answer!</h4>            
                <Fragment>
                {correctAnswer.length>0&&  <div className="quiz-result correct-answer">       
                <div className="d-flex flex-column" >
                    {correctAnswer[0].option!==""&&
                    <h3>{correctAnswer[0].option}</h3>}
                    {correctAnswer[0].image&&
                    <img src={correctAnswer[0].image} />
                    }
                    {/* <h5>Correct Answer</h5> */}
                    </div>                  
                    {correctAnswer[1]&&<div className="quiz-result answer-desc">
                    {/* <h4>Description</h4> */}
                    <p>{correctAnswer[1]}</p>
                </div>}
                </div>}
                </Fragment>                
            </div>
        </div>
    )
}


CorrectAnswer.propTypes = {
    correctAnswer: PropTypes.array.isRequired
}

const mapStateToProps=state=>{
    return{
     correctAnswer:state.QuizV2Reducer.correctAnswer,
     question:state.QuizV2Reducer.question
    }
}


export default connect(mapStateToProps,null)(CorrectAnswer)