import * as actionTypes from "../actionTypes"



export const handleHostLogin = (data) => ({
    type: actionTypes.HOST_AUTH,
    payload: { data }
})


export const handleSuccessLogin = (data) => ({
    type: actionTypes.UPDATE_HOST_AUTH_SUCCESS,
    payload: { data }
})



export const handleFailLogin = (data) => ({
    type: actionTypes.UPDATE_HOST_AUTH_FAIL,
    payload: { data }
})

export const loaderStart = () => ({
    type: actionTypes.HOST_AUTH_START
})


export const loaderFinish = () => ({
    type: actionTypes.HOST_AUTH_FINISH
})


export const setHostCurrentUser=(data)=>({
    type:actionTypes.SET_HOST_CURRENT_USER,
    payload:{data}
})



// export const checkTokenVailid = () => ({
//     type: actionTypes.CHECK_TOKEN_VAILID,
// })

// export const logoutSuccess = () => ({
//     type: actionTypes.UPDATE_AUTH_TOKEN_REMOVE
// })