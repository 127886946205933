import React, { useEffect,Fragment } from 'react'
import PropTypes from "prop-types"
import { photos } from "../../constant/images"
import Countdown from "react-countdown";
import history from "../../helpers/history"
import {Alert} from "rsuite"
import {connect} from "react-redux"

function WrongAnswer({ correctAnswer,question }) {


    return (
        <div className="quiz-result wrong">
            <div className="container" >
                <img src={photos.wrong} />
                <h4>Oh no! Incorrect Answer!</h4>
               
                <Fragment>
                <div className="quiz-result correct-answer">
                {correctAnswer.length>0&&  
                <div className="d-flex flex-column" >
                    {correctAnswer[0].option!==""&&<h3>{correctAnswer[0].option}</h3>}
                    {correctAnswer[0].image&&
                    <img src={correctAnswer[0].image} />
                    }
                    {/* <h5>Correct Answer</h5> */}
                    </div>}
                   
                    {correctAnswer[1]&&<div className="quiz-result answer-desc">
                    {/* <h4>Description</h4> */}
                    <p>{correctAnswer[1]}</p>
                </div>}
                </div>
            
                </Fragment> 
              

            </div>
        </div>
    )
}


WrongAnswer.propTypes = {
    correctAnswer: PropTypes.array.isRequired
}

const mapStateToProps=state=>{
    return{
        correctAnswer:state.QuizV2Reducer.correctAnswer,
        question:state.QuizV2Reducer.question

    }
}


export default connect(mapStateToProps,null)(WrongAnswer)