import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import Countdown, { calcTimeDelta } from "react-countdown";
import history from "../../helpers/history"
export default function Timer({ time, host, setTimerup, setCounting, timerRef }) {

    const countdownComplete = () => {
        setTimerup(true)
            if (host) {
                history.push('/timeup')
        }      
    }

    const renderTime = (obj) => {
        return (
            <h4>{`${ (obj.minutes&& obj.minutes>0&&obj.minutes<10)?"0"+obj.minutes +":":"00:"} ${obj.seconds<10?"0"+obj.seconds:obj.seconds}`}</h4>
        )
    }


    const calulateTime = (obj) => {
        setCounting(obj.seconds)
    }

    return (
        <div className="quiz-timer">
            <Countdown
                ref={timerRef}
                date={Date.now() + 1000 * time}
                controlled={false}
                intervalDelay={1000}
                onPause={(obj) => calulateTime(obj)}
                onComplete={countdownComplete}
                renderer={renderTime}
                onMount={() => setCounting(time)}

            />
        </div>
    )
}




Timer.propTypes = {
    time: PropTypes.any.isRequired,
    host: PropTypes.bool
}
