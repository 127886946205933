import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { store } from "../../redux/store/index"
import { connect } from "react-redux"


const HostRoute = props => {
    const HostAuthenticated = JSON.parse(localStorage.getItem('HostAuthenticated'))
    const PartAuthenticated = JSON.parse(localStorage.getItem('ParticipantAuthenticated'))

    const state = store.getState()
    const Authenticated = state.HostAuthReducer
    const { layout: Layout, component: Component,isHostAuth, ...rest } = props;

    useEffect(()=>{
        console.log("In Host Layout",props)
    },[])

    return (
        <Route
            {...rest}
            render={matchProps => (
                (isHostAuth && (props.path!=='/' || props.path!=='/lobby') ) ?
                    <Layout>
                        <Component {...matchProps} />
                    </Layout> :
                    <Redirect to={{pathname: '/host'}}
                    />
            )}
        />
    );
};

HostRoute.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};

const mapStateToProps=state=>{
    return{
        isPartAuth:state.ParticipantAuthReducer.isAuthenticated,
        isHostAuth:state.HostAuthReducer.isAuthenticated,
    }
}

export default connect(mapStateToProps,null)(HostRoute)