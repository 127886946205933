const photos = {
    exam: require('../assets/img/exam.svg').default,
    logo1: require('../assets/img/logo.png').default,
    banner: require('../assets/img/banner.jpg').default,
    lobby: require('../assets/img/lobby.svg').default,
    avatar1: require('../assets/img/avatar1.svg').default,
    avatar2: require('../assets/img/avatar2.svg').default,
    avatar3: require('../assets/img/avatar3.svg').default,
    correct: require('../assets/img/verification.svg').default,
    wrong: require('../assets/img/cancel.svg').default,
    caution: require('../assets/img/triangle.svg').default,
    trophy: require('../assets/img/award.svg').default,
    attention:require('../assets/img/attention.svg').default,
    cong1:require('../assets/img/cong1.gif').default,
    cong2:require('../assets/img/cong2.gif').default,
    cong3:require('../assets/img/cong3.gif').default,
    info:require('../assets/img/info.svg').default,
    question:require('../assets/img/question.svg').default,
    Page404:require('../assets/img/404.png').default,
    quiz:require('../assets/img/logoBlack.png').default,
    
    
}




export {
    photos
}