import * as actionTypes from "../actionTypes"
import { updateObject } from "./utility"

const initialState = {
    loading: false,
    token: null,
    profile: null,
    error: null,
    user:null,
    isAuthenticated: false,
    quizId:null,
    isNewInstance:null
}



const updateFail = (action, state) => {
    return updateObject(state, {
        error: action.payload.data
    })
}

const updateSuccess = (action, state) => {
    return updateObject(state, {
        // data: action.payload.data,
        token: action.payload.data,
        isAuthenticated: true,
        error: null
    })
}

const LoaderStart = (action, state) => {
    return updateObject(state, {
        loading: true,
        error:null,
    })
}
const LoaderOff = (action, state) => {
    return updateObject(state, {
        loading: false
    })
}

const removeAuth = (action, state) => {
    return updateObject(state, {
        loading: false,
        token: null,
        profile: null,
        error: null,
        user:null,
        isAuthenticated: false,
        pin: null
    })
}

const saveHostPin = (action, state) => {
    return updateObject(state, {
        quizId: action.payload
    })
}

const setProfile=(action,state)=>{
    return updateObject(state,{
        profile:{
            ...state.profile,
            ...action.payload.data},
        isNewInstance:action.payload.data.is_new_instance,
        isAuthenticated:true
    })
}


const changeQuizId=(action,state)=>{
    return updateObject(state,{
        profile:{
            ...state.profile,
            quiz_id:action.payload.id
        }
    })
}


const HostAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_HOST_AUTH_SUCCESS: return updateSuccess(action, state);
        case actionTypes.UPDATE_HOST_AUTH_FAIL: return updateFail(action, state);
        case actionTypes.HOST_AUTH_START: return LoaderStart(action, state)
        case actionTypes.HOST_AUTH_FINISH: return LoaderOff(action, state)
        case actionTypes.REMOVE_TOKEN: return removeAuth(action, state)
        case actionTypes.SAVE_HOST_PIN: return saveHostPin(action, state)
        case actionTypes.SET_HOST_CURRENT_USER:return setProfile(action,state)
        case actionTypes.UPDATE_START_NEW_INSTANCE_SUCCESS:return changeQuizId(action,state)
        default:
            return state
    }
}



export default HostAuthReducer