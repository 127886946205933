import React from 'react'
import PropTypes from 'prop-types';
import { photos } from "../../constant/images"
import AudioPlayer from 'react-h5-audio-player';
import YoutubeQuestion from "./YoutubeQuestion"
import ReactHtmlParser from "react-html-parser"
export default function Question({ question, questionType, file,startTime,endTime }) {
    return (
        <div className="quiz-question" >
            {questionType.toLowerCase() == "text" && 
            <div className="quiz-question__text" >
                {/* <img src={photos.question} /> */}
                <h4>{ReactHtmlParser(question)}</h4>
            </div>}
            {
                (questionType.toLowerCase() == "image" || questionType.toLowerCase() == "audio" || questionType.toLowerCase() == 'video') &&
                <div className="quiz-question__image" >
                    <div className="quiz-question__image__info" >
                        {/* <img src={photos.question} /> */}
                        {/* <h4>{question}</h4> */}
                        <h4>{ReactHtmlParser(question)}</h4>

                    </div>
                    <div className="quiz-question__image__not-text" >
                        {questionType.toLowerCase() == "image" && <img src={file} />}
                        {questionType.toLowerCase() == "audio" &&
                            <AudioPlayer src={file} />
                        }
                        {
                            questionType.toLowerCase() == "video" &&
                            <YoutubeQuestion link={file} startTime={startTime} endTime={endTime} />
                        }
                    </div>
                </div>
            }
        </div>
    )
}


Question.propTypes = {
    question: PropTypes.string.isRequired,
    questionType: PropTypes.string.isRequired,
    file: PropTypes.any
}
