import * as actionTypes from "../actionTypes"
import { updateObject } from "./utility"

const initialState = {
    quizloading: false,
    questionloading: false,
    participantLoading: false,
    quiz: null,
    question: null,
    quizError: null,
    questionError: null,
    participant: [],
    startQuiz: false,
    startQuizLoding: false,
    submittedAnswer: null,
    submitLoading: false,
    error: null,
    showLeaderBoard: false,
    leaderboardLoading: false,
    correctAnswer: [],
    showNextQuestion: null,
    isNoAnswerGiven: false,
    isCorrectAnswer: false,
    isWrongAnswer: false,
    isInternetStable:true
}



const updateQuizFail = (action, state) => {
    return updateObject(state, {
        quizError: action.payload.data
    })
}

const updateQuestionFail = (action, state) => {
    return updateObject(state, {
        // questionError: action.payload.data,
        question:null
    })
}

const updateQuizSuccess = (action, state) => {
    return updateObject(state, {
        quiz: action.payload.data,
        
    })
}

const updateQuestionSuccess = (action, state) => {
    return updateObject(state, {
        question: action.payload.data,
        submittedAnswer: null,
        isNoAnswerGiven: false,
        isCorrectAnswer: false,
        isWrongAnswer: false
    })
}


const quizLoaderStart = (action, state) => {
    return updateObject(state, {
        quizloading: true
    })
}
const quizLoaderOff = (action, state) => {
    return updateObject(state, {
        quizloading: false
    })
}


const questionLoaderStart = (action, state) => {
    return updateObject(state, {
        questionloading: true
    })
}
const questionLoaderOff = (action, state) => {
    return updateObject(state, {
        questionloading: false
    })
}


const updateParticipantSuccess = (action, state) => {
    return updateObject(state, {
        participant: action.payload.data,
        submittedAnswer: null,
        isNoAnswerGiven: false,
        isCorrectAnswer: false,
        isWrongAnswer: false
    })
}

const participantLoaderStart = (action, state) => {
    return updateObject(state, {
        participantLoading: true
    })
}

const participantLoaderFinish = (action, state) => {
    return updateObject(state, {
        participantLoading: false
    })
}

const quizStartSuccess = (action, state) => {
    return updateObject(state, {
        startQuiz: !state.startQuiz,

    })
}

const startQuizLoadingStart = (action, state) => {
    return updateObject(state, {
        startQuizLoading: true
    })
}

const startQuizLoadingFinish = (action, state) => {
    return updateObject(state, {
        startQuizLoading: false
    })
}

const submitSuccess = (action, state) => {
    return updateObject(state, {
        submittedAnswer: action.payload.data
    })
}

const submitLoadingStart = (action, state) => {
    return updateObject(state, {
        submitLoading: true
    })
}

const submitLoadingFinish = (action, state) => {
    return updateObject(state, {
        submitLoading: false
    })
}

const showLeaderBoardSuccess = (action, state) => {
    return updateObject(state, {
        showLeaderBoard: true,
        isNoAnswerGiven: false,
        isCorrectAnswer: false,
        isWrongAnswer: false,
        question:null,
        correctAnswer: [],
        quiz: {
            ...state.quiz,
            question_start: false
        },
        question: null,
    })
}

const hideLeaderBoard = (action, state) => {
    return updateObject(state, {
        showLeaderBoard: false
    })
}


const leaderboardLoadingStart = (action, state) => {
    return updateObject(state, {
        leaderboardLoading: true
    })
}

const leaderboardLoadingFinish = (action, state) => {
    return updateObject(state, {
        leaderboardLoading: false
    })
}

const saveCorrectAnswer = (action, state) => {
    return updateObject(state, {
        correctAnswer: action.payload.data
    })
}

const updateShowNextQuestion = (action, state) => {
    return updateObject(state, {
        showNextQuestion: action.payload.data
    })
}


const answerAlreadyGiven=(action,state)=>{
    return updateObject(state,{
        question: null,
    })
}

const noAnswerGiven = (action, state) => {
    return updateObject(state, {
        isNoAnswerGiven: true
    })
}
const wrongAnswer = (action, state) => {
    return updateObject(state, {
        isWrongAnswer: true
    })
}

const correctAnswer = (action, state) => {
    return updateObject(state, {
        isCorrectAnswer: true
    })
}

const resetAll=(action,state)=>{
    return updateObject(state,{
        quizloading: false,
        questionloading: false,
        participantLoading: false,
        quiz: null,
        question: null,
        quizError: null,
        questionError: null,
        participant: [],
        startQuiz: false,
        startQuizLoding: false,
        submittedAnswer: null,
        submitLoading: false,
        error: null,
        showLeaderBoard: false,
        leaderboardLoading: false,
        correctAnswer: [],
        showNextQuestion: null,
        isNoAnswerGiven: false,
        isCorrectAnswer: false,
        isWrongAnswer: false
    })
}

const internetStable=(action,state)=>{
    return updateObject(state,{
        isInternetStable:true
    })
}

const internetUnstable=(action,state)=>{
    return updateObject(state,{
        isInternetStable:false
    })
}

const QuizReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_GCQ_SUCCESS: return updateQuestionSuccess(action, state);
        case actionTypes.UPDATE_GCQ_FAIL: return updateQuestionFail(action, state);
        case actionTypes.UPDATE_CQ_SUCCESS: return updateQuizSuccess(action, state);
        case actionTypes.UPDATE_CQ_FAIL: return updateQuizFail(action, state);
        case actionTypes.GCQ_START: return questionLoaderStart(action, state)
        case actionTypes.GCQ_FINISH: return questionLoaderOff(action, state)
        case actionTypes.CQ_START: return quizLoaderStart(action, state)
        case actionTypes.CQ_FINISH: return quizLoaderOff(action, state)
        case actionTypes.UPDATE_GPL_SUCCESS: return updateParticipantSuccess(action, state)
        case actionTypes.GPL_START: return participantLoaderStart(action, state)
        case actionTypes.GPL_FINISH: return participantLoaderFinish(action, state)
        case actionTypes.UPDATE_START_QUIZ_SUCCESS: return quizStartSuccess(action, state)
        case actionTypes.START_QUIZ_START: return startQuizLoadingStart(action, state)
        case actionTypes.START_QUIZ_FINISH: return startQuizLoadingFinish(action, state)
        case actionTypes.UPDATE_SUBMIT_ANSWER_SUCCESS: return submitSuccess(action, state)
        case actionTypes.SUBMIT_ANSWER_START: return submitLoadingStart(action, state)
        case actionTypes.SUBMIT_ANSWER_FINISH: return submitLoadingFinish(action, state)
        case actionTypes.UPDATE_SHOW_LEADERBOARD_SUCCESS: return showLeaderBoardSuccess(action, state)
        case actionTypes.HIDE_SHOW_LEADERBOARD_FAIL: return hideLeaderBoard(action, state)
        case actionTypes.SHOW_LEADERBOARD_START: return leaderboardLoadingStart(action, state)
        case actionTypes.SHOW_LEADERBOARD_FINISH: return leaderboardLoadingFinish(action, state)
        case actionTypes.SAVE_CORRECT_ANSWER: return saveCorrectAnswer(action, state)
        case actionTypes.UPDATE_SHOW_NEXT_QUESTION_SUCCESS: return updateShowNextQuestion(action, state)
        case actionTypes.NO_ANSWER_GIVEN: return noAnswerGiven(action, state)
        case actionTypes.ANSWER_IS_CORRECT: return correctAnswer(action, state)
        case actionTypes.ANSWER_IS_WRONG: return wrongAnswer(action, state)
        case actionTypes.ANSWER_ALREADY_GIVEN: return answerAlreadyGiven(action, state)
        case actionTypes.REMOVE_TOKEN:return resetAll(action,state)
        case actionTypes.INTERNET_STABLE:return internetStable(action,state)
        case actionTypes.INTERNET_UNSTABLE:return internetUnstable(action,state)
        default:
            return state
    }
}



export default QuizReducer