/** @format */

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import HostAuthReducer from "./HostAuthReducer"
import ParticipantAuthReducer from "./ParticipantAuthReducer"
import QuizReducer from "./QuizReducer"
import QuizV2Reducer from "./QuizV2Reducer"
import { reducer as formReducer } from "redux-form";


const persistConfig = {
  key: "root",
  storage, whitelist: [],
  blacklist: ['HostAuthReducer', 'ParticipantAuthReducer','QuizReducer']
};
const HostAuthConfig = {
  key: 'HostAuthReducer',
  storage,
  blacklist: ['loading', 'error','isNewInstance']
}

const ParticipantAuthConfig = {
  key: 'ParticipantAuthReducer',
  storage,
  blacklist: ['loading', 'error']
}

const QuizConfig = {
  key: 'QuizReducer',
  storage,
  whitelist:['submittedAnswer']
}

const QuizV2Config = {
  key: 'QuizV2Reducer',
  storage,
  whitelist:['submittedAnswer','allQuestion']
}



const Reducers = combineReducers({
  HostAuthReducer: persistReducer(HostAuthConfig, HostAuthReducer),
  ParticipantAuthReducer: persistReducer(ParticipantAuthConfig, ParticipantAuthReducer),
  QuizReducer: persistReducer(QuizConfig, QuizReducer),
  QuizV2Reducer:persistReducer(QuizV2Config,QuizV2Reducer),
  form: formReducer,
});



export default persistReducer(persistConfig, Reducers);
