import React,{Fragment} from 'react'
import {connect} from "react-redux"
import classname from "classnames"


function Indicator({isConnected,Host,Part}) {

    const circleWrapper=classname('indicator',{
        'connected':isConnected,
        'disconnected':!isConnected
    })


    return (
        <Fragment>
        {(Host || Part)&&<div className={circleWrapper}>
            <div className="indicator__box" >
                <h4>{!isConnected?'Trying to Connect..':'Connected'}</h4>
                <div  ></div>
            </div>    
        </div>}
        </Fragment>
    )
}



const mapStateToProps=state=>{
    return{
        isConnected:state.QuizV2Reducer.isWebSocketConnected,
        Host:state.HostAuthReducer.profile,
        Part:state.ParticipantAuthReducer.data
    }
}

export default connect(mapStateToProps)(Indicator)