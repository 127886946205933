import React,{useEffect} from 'react'
import Countdown from "react-countdown";
import Loader from 'react-loader-spinner'




export default function AnswerSubmitted({ timer, setTimerUp }) {

    useEffect(()=>{
        window.location.hash = "no-back-button";
        window.location.hash = "Again-No-back-button"; 
        window.onhashchange = function(){
            window.location.hash = "no-back-button";
        }
    },[])

    return (
        <div className="answer-submitted d-flex flex-column justify-content-center align-items-center text-center pl-20 pr-20" >
            <Loader type="TailSpin" color="#F5953C" height={100} width={100} />
            <h2>Thanks for submitting your answer!</h2>
            <Countdown
                date={Date.now() + 1000 * (timer-1)}
                renderer={(obj) => {
                    if(obj.seconds>0){
                        return (
                            <h3>{`Please wait ${ (obj.minutes&& obj.minutes>0&&obj.minutes<10)? "0"+obj.minutes +":" : ""} ${obj.seconds<10?"0"+obj.seconds:obj.seconds} seconds.`}</h3>
                        )
                    }else{
                        return(<h3>Just a moment...</h3>)
                    }
                   
                }}
                onComplete={() => setTimerUp(true)}
            />
        </div>
    )
}
