//@author:Himanshu Barmola



// Customizating the PasswordInput field for Redux-Form

import React, { useState } from "react"
import { Input, InputGroup } from "rsuite"
import { RiEye2Line, RiEyeCloseLine } from "react-icons/all"

export const PasswordInput = (props) => {
    const [eye, setEye] = useState(false)

    return (
        <div className="input-field">
            <InputGroup
                className={props.meta.touched && props.meta.error != undefined ? "error-field" : undefined}
            >
                <Input
                    {...props.input}
                    placeholder={props.placeholder}
                    value={props.input.value}
                    name={props.input.name}
                    type={eye ? "text" : "password"}
                    block="true"
                    size="lg"
                />
                <InputGroup.Addon onClick={() => setEye(!eye)} >
                    {eye ? <RiEye2Line /> : <RiEyeCloseLine />}
                </InputGroup.Addon>
            </InputGroup>
            <p id="error-msg" >{props.meta.touched ? props.meta.error : ""}</p>
        </div>
    )
}



