import React,{useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { store } from "../../redux/store/index"
import { connect } from "react-redux"
import * as trigger from "../../redux/actions/quizV2"



export const RouteLayout = props => {
    const HostAuthenticated = JSON.parse(localStorage.getItem('HostAuthenticated')) || false
    const PartAuthenticated = JSON.parse(localStorage.getItem('ParticipantAuthenticated')) ||false
    const { layout: Layout, component: Component,isPartAuth,isHostAuth,_checkQuizOngoing, ...rest } = props;

   useEffect(()=>{
       console.log("In Route Layout",isHostAuth, isPartAuth)
     
   },[])
    return (
        <Route
            {...rest}
            render={matchProps => (
                (!isPartAuth || !isHostAuth)   ?
                    <Layout>
                        <Component {...matchProps} />
                    </Layout> 
                    :<Redirect to="/" />
            )}
        />
    );
};

RouteLayout.propTypes = {
    component: PropTypes.any,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};


const mapStateToProps=state=>{
    return{
        isPartAuth:state.ParticipantAuthReducer.isAuthenticated,
        isHostAuth:state.HostAuthReducer.isAuthenticated,
    }
}

const mapDispatchToProps=dispatch=>{
    return{
        _checkQuizOngoing:()=>dispatch(trigger.checkQuizOngoing())
    }
}

export default connect(mapStateToProps,null)(RouteLayout)