import React from 'react'
import PropTypes from 'prop-types';

export default function QuestionNumber({ currentQuestion, totalQuestion }) {
    return (
        <div className="quiz-number" >
            <div className="d-flex">
                <div className="question-top">
                    <p className="question-no">{currentQuestion}</p>
                </div>
                <div className="question-q">
                    <p className="question-tag">/&nbsp;{totalQuestion}</p>
                </div>
            </div>
        </div>

    )
}
QuestionNumber.propTypes = {
    currentQuestion: PropTypes.number.isRequired,
    totalQuestion: PropTypes.number.isRequired
}
