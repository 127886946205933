import React,{useEffect,useState,useRef,Fragment} from 'react'
import {connect} from "react-redux"
import * as action from "../redux/actions/quizV2"
import * as actionTypes from "../redux/actionTypes"
import history from '../helpers/history'
import {useQuery} from 'react-query'
import * as API from '../api/api'
import {apiRequest} from '../helpers/apiRequest'
import Websocket from 'react-websocket'
import ReconnectingWebSocket from 'reconnecting-websocket';


function WebSocketProvider(props) {
    const {HostQuizID,dispatch,PartQuizID,quizStatus}=props
    const QuestionId=quizStatus?.question_id
    // const [QuizId,setQuizID]=useState(HostQuizID?.quiz_id?HostQuizID.quiz_id:PartQuizID?.quiz_id)
    var QuizId=HostQuizID?.quiz_id?HostQuizID.quiz_id:PartQuizID?.quiz_id
    const [isConnected,setIsConnected]=useState(false)
    const [fetchTime,setFetchTime]=useState(false)
    const [showParticipant,setShowParticipant]=useState(false)
    var wsRef=useRef()
    const {}=useQuery(['fetchTime'],()=>apiRequest('GET',API.TIME_REMAINING,null,{question_id:QuestionId}),{
        enabled:fetchTime,
        onSuccess:(data)=>{
            dispatch({type:actionTypes.UPDATE_GET_REMAINING_TIME_SUCCESS,payload:{data:data.remaining}})
        },
        refetchInterval:3000
    })


   

    useEffect(()=>{
        window.addEventListener('offline',(e)=>{
            QuizId=null;
            dispatch(action.websocketDisconnected())
            

        })
        window.addEventListener('online',(e)=>{
            setTimeout(()=>{
                dispatch(action.websocketConnected())
            },4500)
            QuizId=HostQuizID?.quiz_id?HostQuizID.quiz_id:PartQuizID?.quiz_id
        })
    },[])

    
     
    


    
   
   
  



function handleData(message){
    console.log("=====WebSocket Message Received====",JSON.parse(message))
    const data=JSON.parse(message)
    dispatch(action.updateQuizStatus(data))
    if(data.show_screen=="leaderboard"){
        dispatch(action.getParticpants())
       
        if(props.allQuestion==null){
            dispatch({type:actionTypes.FETCH_ALL_QUESTION})
        }
        if(localStorage.HostJwtToken!==undefined && history.location.pathname!=='/host' ){
            history.push('/host')
        }else{
            history.push('/lobby')
            dispatch({type:actionTypes.SHOW_LEADERBOARD_SUCCESS})
        }
        }
        if(data.show_screen==="question" && data.question_id!==null){ 
            if(props.allQuestion==null){
                dispatch({type:actionTypes.FETCH_ALL_QUESTION})
            }        
                dispatch({type:actionTypes.GET_CURRENT_QUESTION})
                if(!fetchTime){
                    setFetchTime(true)
                }
                if(showParticipant){
                    setShowParticipant(false)
                }
                if(history.location.pathname!=="/host-quiz"){
                    console.log("Redirecting Host to Quiz")
                   history.push('/host-quiz')     
               }
               // If it is Participant then navigating him to a Seprate Screen where he can select answers
               if(history.location.pathname=="/lobby"){
                   console.log("Redirecting Participant to Quiz")
                   history.push('/participant-quiz')
               }
        
        }
        if(data.show_screen==="answer"){
            if(localStorage.HostJwtToken!==undefined){
                history.push('/timeup')
            }else{
                dispatch({type:actionTypes.SHOW_ANSWER_SCREEN})
            }
        }
}


function handleSendId(){
    console.log("Websocket Connected")
    wsRef.sendMessage(QuizId)
    dispatch(action.websocketConnected())
}


    return (
        <Fragment>
        {QuizId&&
        <Websocket
        // url={`ws://quizapp.qilinlab.com/ws/current_screen/${QuizId}/`}
        url={`wss://quizapp.qilinlab.com/ws/current_screen/${QuizId}/`}
        onOpen={handleSendId}
        onMessage={handleData}  
        reconnect
        debug={true}
        onClose={(v)=>dispatch(action.websocketDisconnected())}
        ref={Websockets=>{wsRef=Websockets}}
        />}
        </Fragment>
    )
}


const mapStateToProps=(state)=>{
    return{
        HostQuizID:state.HostAuthReducer.profile,
        PartQuizID:state.ParticipantAuthReducer.data,
        allQuestion:state.QuizV2Reducer.allQuestion,
        question:state.QuizV2Reducer.question,
        quizStatus:state.QuizV2Reducer.quizStatus
    }
}



export default connect(mapStateToProps,null)(WebSocketProvider)