import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { store } from "../../redux/store/index"
import { connect } from "react-redux"
import * as action from "../../redux/actions/quizV2"
const ProtectedRoute = props => {
    const { layout: Layout, component: Component,isHostAuth,_checkQuizOngoing,_checkQuizStatus, ...rest } = props;
    const HostAuthenticated = JSON.parse(localStorage.getItem('HostAuthenticated')) || false
    const isPartAuth = JSON.parse(localStorage.getItem('ParticipantAuthenticated')) ||false
    const HostPath=['/host','/host-quiz','/timeup','/']
    const PartPath=['/lobby','/participant-quiz','/','/wrong-answer','/correct-answer','/no-answer']
    useEffect(() => {
        console.log("Is Host Authenticated", isHostAuth)
        console.log("Is Part Authenticated", isPartAuth )
        if(isHostAuth || isPartAuth){
            _checkQuizOngoing()
            _checkQuizStatus()
        }

    }, [])


    useEffect(()=>{
        console.log("In Protected Layout",isPartAuth)
    },[])

    const checkHostPath=(path,host)=>{
        if(host=='host'){
            let isExist=HostPath.filter((item)=>{return item==path})
            console.log("isExist Host: ",isExist)
               if( isExist.length>0){
                   return true
               }else{
                   return false
               }
        }
        if(host=='part'){
            let isExist=PartPath.filter((item)=>{return item==path})
            console.log("isExist Part: ",isExist)
               if( isExist.length>0){
                   return true
               }else{
                   return false
               }
        }
  
   }

    return (
        <Route
            {...rest}
            render={matchProps => (
                (isPartAuth&& !checkHostPath(props.path,"host") )?<Layout><Component {...matchProps} /></Layout> :
                (isHostAuth&& !checkHostPath(props.path,"part") )?<Layout><Component {...matchProps} /></Layout> :
                isHostAuth?<Redirect to="/host" />:
                isPartAuth?<Redirect to="/lobby"/>:
                props.path=="/host"?
                <Redirect to="/host-login" />:
                <Redirect to="/"/>
            )}
        />
    );
};

ProtectedRoute.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
};

const mapStateToProps=state=>{
    return{
        // isPartAuth:state.ParticipantAuthReducer.isAuthenticated,
        isHostAuth:state.HostAuthReducer.isAuthenticated,
    }
}

const mapDispatchToProps=dispatch=>{
    return{
    _checkQuizOngoing:()=>dispatch(action.checkQuizOngoing()),
    _checkQuizStatus:()=>dispatch(action.checkQuizStatus())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProtectedRoute)