import * as actionTypes from "../actionTypes"
import { apiRequest } from "../actions/api"
import * as API from "../../api/api"
import {
    questionLoaderStart, questionLoaderFail, getParticipantList, updateSubmitAnswerSuccess, updateShowLeaderBoard, udpateShowNextQuestion,
    quizLoaderStart, quizLoaderFail, handleQuestionUpdateSuccess, handleQuestionUpdateFail, handleQuizUpdateFail, handleQuizUpdateSuccess,
    
} from "../actions/quiz";
import { store } from "../store/index"
import history from "../../helpers/history"
import { Alert } from 'rsuite'
import { CgNotes } from "react-icons/cg";

var apiData = null
const isPartAuth = JSON.parse(localStorage.getItem('ParticipantAuthenticated'))


export const checkQuiz = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CHECK_QUIZ) {
        const state = store.getState()
        const pin = state.ParticipantAuthReducer.pin !== null ? state.ParticipantAuthReducer.pin : state.HostAuthReducer.pin
        const quiz=state.QuizReducer.quiz
        const submittedAnswer=state.QuizReducer.submittedAnswer

        let data = { pin };
        apiData = data
        let onSuccess = actionTypes.CQ_SUCCESS;
        let onFail = actionTypes.CQ_FAIL;
        console.log("Is Loged in: ",isPartAuth)
        if (history.location.pathname !== "/host-quiz" && ((quiz==null || (quiz!==null&& !quiz.question_start )|| submittedAnswer!==null)) ) {
            dispatch(apiRequest('GET', API.CHECK_QUIZ_START, data, onSuccess, onFail, null, null))
            dispatch(quizLoaderStart())
        }
    }
}

export const getParticipantLists = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GET_PARTICIPANT_LIST) {
        let data = action.payload.data;
        let onSuccess = actionTypes.GPL_SUCCESS;
        let onFail = actionTypes.GPL_FAIL
        dispatch(apiRequest('GET', API.PARTICIPANT_LIST, data, onSuccess, onFail, null, null))
    }
}

export const handleQuizSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CQ_SUCCESS) {
        const state = store.getState()
        const pin = state.ParticipantAuthReducer.pin !== null ? state.ParticipantAuthReducer.pin : state.HostAuthReducer.pin
        const quizLoading=state.QuizReducer.quizLoading
        let data = action.payload;

        if (data) {
            dispatch(handleQuizUpdateSuccess(data))
            if (history.location.pathname !== "/host-quiz") {
                console.log("Location name: ", history.location)
                if(!data.quiz_ongoing && data.question_no==null){
                        dispatch({type:actionTypes.LOGOUT})
                   }

                if (!data.question_start  ) {
                    console.log("Requesting Quiz: ",quizLoading)
                    setTimeout(() => {
                        dispatch({ type: actionTypes.CHECK_QUIZ, payload: { data: { pin } } })
                    }, 1000)
                    if(data.question_no==1){
                        setTimeout(() => {
                            dispatch({ type: actionTypes.GET_PARTICIPANT_LIST, payload: { data: { pin } } })
                        }, 2000)
                    }
                }

                if (data.show_leaderboard) {
                    dispatch(updateShowLeaderBoard())
                }
                if (!data.show_leaderboard ) {
                    dispatch({ type: actionTypes.HIDE_SHOW_LEADERBOARD_FAIL })
                }
                if(!data.question_start){
                    // dispatch({type:actionTypes.UPDATE_GCQ_FAIL})
                }

                if (data.question_start) {
                    let data = {
                        pin
                    }
                    let onSuccess = actionTypes.GCQ_SUCCESS;
                    let onFail = actionTypes.GCQ_FAIL;
                    console.log("Fetching Current Question in Start Quiz")
                    dispatch(apiRequest('GET', API.GET_CURRENT_QUESTION, data, onSuccess, onFail, null, null))
                    dispatch(questionLoaderStart())
                } 
            }
        }

        dispatch(quizLoaderFail())  
    }
}

export const handleQuizFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.CQ_FAIL) {
        const state = store.getState()
        const pin = state.ParticipantAuthReducer.pin !== null ? state.ParticipantAuthReducer.pin : state.HostAuthReducer.pin
        let data = action.payload;
        // Alert.warning('Something Went Wrong. Please try to refresh page', 2000)
        setTimeout(() => {
            dispatch({ type: actionTypes.CHECK_QUIZ, payload: { data: { pin } } })
        }, 1000)

        dispatch(quizLoaderFail())
    }
}


export const handleQuestionSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GCQ_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch({ type: actionTypes.HIDE_SHOW_LEADERBOARD_FAIL })
            dispatch(handleQuestionUpdateSuccess(data[0]))
            if (history.location.pathname == "/lobby") {
                console.log("Redirecting it to the Quizzz")
                history.push('/participant-quiz')
            }
            if (history.location.pathname == "/host") {
                history.push('/host-quiz')
            }
        }
        dispatch(questionLoaderFail())
    }
}

export const handleQuestionFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GCQ_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch(handleQuestionUpdateFail(data))
        }
        dispatch(questionLoaderFail())
    }
}



export const handleParticipantListSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GPL_SUCCESS) {
        let data = action.payload;
        const state = store.getState()
        const pin = state.ParticipantAuthReducer.pin !== null ? state.ParticipantAuthReducer.pin : state.HostAuthReducer.pin
        const question = state.QuizReducer.question
        const quiz = state.QuizReducer.quiz
        if (data) {
            dispatch({ type: actionTypes.UPDATE_GPL_SUCCESS, payload: { data } })
            if (history.location.pathname !== '/host' && !quiz.question_start && data[0].quiz_ongoing && quiz.show_leaderboard) {
            // if (history.location.pathname !== '/host') {
                setTimeout(() => {
                    dispatch({ type: actionTypes.GET_PARTICIPANT_LIST, payload: { data: { pin } } })
                }, 2000)
                dispatch({ type: actionTypes.UPDATE_GPL_SUCCESS, payload: { data } })
            }
            dispatch({ type: actionTypes.UPDATE_GPL_SUCCESS, payload: { data } })

            if (history.location.pathname == "/host" && history.location.pathname !== "/participant-quiz"  ) {
                setTimeout(() => {
                    let datas = { pin }
                    dispatch(getParticipantList(datas))
                    if (data.length > 0 && data[0].current_question_no == null) {
                        dispatch({ type: actionTypes.UPDATE_START_QUIZ_SUCCESS })
                    }
                }, 2000)
                dispatch({ type: actionTypes.UPDATE_GPL_SUCCESS, payload: { data } })
                if (quiz.question_start && data.quiz_ongoing ) {
                    let data = {
                        pin
                    }
                    let onSuccess = actionTypes.GCQ_SUCCESS;
                    let onFail = actionTypes.GCQ_FAIL;
                    console.log("Fetching Current Question in Participant List")
                    dispatch(apiRequest('GET', API.GET_CURRENT_QUESTION, data, onSuccess, onFail, null, null))
                    dispatch(questionLoaderStart())
                }

            }

        }
    }
}

export const handleParticipantListFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.GPL_FAIL) {
        let data = action.payload;
        console.log("GPL FAIL", data)
        if (data) {
            dispatch({ type: actionTypes.UPDATE_GPL_FAIL, payload: { data } })
        }
    }
}

export const handleStartQuiz = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.START_QUIZ) {
        let data = action.payload.data;
        let onSuccess = actionTypes.START_QUIZ_SUCCESS;
        let onFail = actionTypes.START_QUIZ_FAIL;
        dispatch(apiRequest('POST', API.START_QUIZ, null, onSuccess, onFail, data, null))
        dispatch({ type: actionTypes.START_QUIZ_START })
    }
}

export const handleStartQuizSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.START_QUIZ_SUCCESS) {
        let data = action.payload;
        if (data) {
            const state = store.getState()
            const pin = state.ParticipantAuthReducer.pin !== null ? state.ParticipantAuthReducer.pin : state.HostAuthReducer.pin
            // if(history.location.pathname=="/host"){
            //     history.push('/host-screen')
            // }
            let data = {
                pin
            }
            let onSuccess = actionTypes.GCQ_SUCCESS;
            let onFail = actionTypes.GCQ_FAIL;
            dispatch(apiRequest('GET', API.GET_CURRENT_QUESTION, data, onSuccess, onFail, null, null))
            dispatch({ type: actionTypes.UPDATE_START_QUIZ_SUCCESS })
        }
        dispatch({ type: actionTypes.START_QUIZ_FINISH })
    }
}
export const handleStartQuizFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.START_QUIZ_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch({ type: actionTypes.UPDATE_START_QUIZ_FAIL })
        }
        dispatch({ type: actionTypes.START_QUIZ_FINISH })
    }
}


export const submitAnswer = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SUBMIT_ANSWER) {
        let data = action.payload.data;
        let onSuccess = actionTypes.SUBMIT_ANSWER_SUCCESS;
        let onFail = actionTypes.SUBMIT_ANSWER_FAIL;
        dispatch(apiRequest('POST', API.SUBMIT_ANSWER, null, onSuccess, onFail, data, null))
        dispatch({ type: actionTypes.SUBMIT_ANSWER_START })
    }
}

export const submitAnswerSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SUBMIT_ANSWER_SUCCESS) {
        let data = action.payload;
        if (data) {
            const state = store.getState()
            const correctAnswer = state.QuizReducer.correctAnswer
            console.log("Correct Answer: ", correctAnswer)
            dispatch(updateSubmitAnswerSuccess(data))
            console.log("Data in submit: ", data)
            if (data.msg == 'CORRECT ANSWER') {
                dispatch({ type: actionTypes.ANSWER_IS_CORRECT })
            }
            else if (data.msg == 'WRONG ANSWER') {
                dispatch({ type: actionTypes.ANSWER_IS_WRONG })
            }
            else if (data.msg == 'NO ANSWER') {
                dispatch({ type: actionTypes.NO_ANSWER_GIVEN })
            }
            else {
                dispatch({type:actionTypes.ANSWER_ALREADY_GIVEN})
                history.push('/lobby')
            }

        }
        dispatch({ type: actionTypes.SUBMIT_ANSWER_FINISH })

    }
}

export const submitAnswerFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SUBMIT_ANSWER_FAIL) {
        let data = action.payload;
        if (data) {

        }
        dispatch({ type: actionTypes.SUBMIT_ANSWER_FINISH })
    }
}


export const showLeaderBoard = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SHOW_LEADERBOARD) {
        let data = action.payload.data;
        let onSuccess = actionTypes.SHOW_LEADERBOARD_SUCCESS;
        let onFail = actionTypes.SHOW_LEADERBOARD_FAIL;

        dispatch(apiRequest('POST', API.SHOW_LEADERBOARD, null, onSuccess, onFail, data, null))
        dispatch({ type: actionTypes.SHOW_LEADERBOARD_START })
    }
}

export const showLeaderBoardSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SHOW_LEADERBOARD_SUCCESS) {
        let data = action.payload;
        if (data) {
            dispatch(updateShowLeaderBoard())
            if (history.location.pathname == '/timeup') {
                history.push('/host')
            }
            if (history.location.pathname == '/participant-quiz') {
                history.push('/lobby')
            }
        }
        dispatch({ type: actionTypes.SHOW_LEADERBOARD_FINISH })

    }
}

export const showLeaderBoardFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SHOW_LEADERBOARD_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch({ type: actionTypes.HIDE_SHOW_LEADERBOARD_FAIL })
            // dispatch(updateShowLeaderBoard(data))
        }
        dispatch({ type: actionTypes.SHOW_LEADERBOARD_FINISH })

    }
}


export const showNextQuestion = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SHOW_NEXT_QUESTION) {
        let data = action.payload.data;
        let onSuccess = actionTypes.SHOW_NEXT_QUESTION_SUCCESS;
        let onFail = actionTypes.SHOW_NEXT_QUESTION_FAIL;
        dispatch(apiRequest('POST', API.NEXT_QUESTION, null, onSuccess, onFail, data, null))
        dispatch({ type: actionTypes.START_QUIZ_START })
    }
}

export const showNextQuestionSuccess = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SHOW_NEXT_QUESTION_SUCCESS) {
        let datas = action.payload;
        if (datas) {
            const state = store.getState()
            const pin = state.ParticipantAuthReducer.pin !== null ? state.ParticipantAuthReducer.pin : state.HostAuthReducer.pin
            let data = {
                pin
            }
            dispatch({ type: actionTypes.CHECK_QUIZ, payload: { data } })
            dispatch(udpateShowNextQuestion(datas))

        }
        dispatch({ type: actionTypes.START_QUIZ_FINISH })

    }
}

export const showNextQuestionFail = ({ dispatch }) => next => action => {
    next(action)
    if (action.type === actionTypes.SHOW_NEXT_QUESTION_FAIL) {
        let data = action.payload;
        if (data) {
            dispatch(udpateShowNextQuestion(data))
        }
        dispatch({ type: actionTypes.START_QUIZ_FINISH })

    }
}

export const endQuiz=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.END_QUIZ){
        const state = store.getState()
        const pin = state.ParticipantAuthReducer.pin !== null ? state.ParticipantAuthReducer.pin : state.HostAuthReducer.pin
        let data = { pin };
        let onSuccess=actionTypes.END_QUIZ_SUCCESS;
        let onFail=actionTypes.END_QUIZ_FAIL;
        dispatch(apiRequest('POST',API.END_QUIZ,null,onSuccess,onFail,data,null))
        dispatch({type:actionTypes.HOST_AUTH_START})
    }
}

export const endQuizSuccess=({dispatch})=>next=>action=>{
    next(action)
    if(action.type===actionTypes.END_QUIZ_SUCCESS){
         let data=action.payload;
         if(data){
            dispatch({type:actionTypes.LOGOUT})

         }
         dispatch({type:actionTypes.HOST_AUTH_FINISH})

    }
}

export const endQuizFail=({dispatch})=>next=>action=>{
    next(action)
    if(action.type === actionTypes.END_QUIZ_FAIL){
        let data=action.payload;
        if(data){

        }
        dispatch({type:actionTypes.HOST_AUTH_FINISH})
    }
}

export const logout=({dispatch})=>next=>action=>{
    next(action)
    if(action.type ===actionTypes.LOGOUT){
        localStorage.removeItem("HostJwtToken");
        localStorage.setItem('ParticipantAuthenticated',false)
        dispatch({type:actionTypes.REMOVE_TOKEN})
        history.push('/')

        setTimeout(()=>{
            window.location.reload()
            Alert.warning('The Quiz has been ended or not yet been started by Host, You will be redirecting to the login page,',7000)
        },2000)
      

    }
}



export const quizMdl = [
    checkQuiz,
    handleQuizSuccess,
    handleQuizFail,
    handleQuestionSuccess,
    handleQuestionFail,
    getParticipantLists,
    handleParticipantListSuccess,
    handleParticipantListFail,
    handleStartQuiz,
    handleStartQuizFail,
    handleStartQuizSuccess,
    submitAnswer,
    submitAnswerSuccess,
    submitAnswerFail,
    showLeaderBoard,
    showLeaderBoardSuccess,
    showLeaderBoardFail,
    showNextQuestion,
    showNextQuestionSuccess,
    showNextQuestionFail,
    endQuiz,
    endQuizSuccess,
    endQuizFail,
    logout,
]